import { NavLink } from 'react-router-dom'
import '../styles/GeneralNavBar.scss'
import { useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const MobileSideBar = () => {

  return (
    <div id='MobileSideBar' className='mobile'>
      <div className="userImag">
        <img src="" alt=""/>
        <h2>Welcome Leslie Alexander</h2>
      </div>
      <NavIteams title="Customers">
        <div className="makeSpacing">
          <NavLink to="/csdasboard" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Customer Dashboard</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/add-customer" className={({ isActive }) =>
            isActive ? "act" : undefined
          }>Add New Customer</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/list" className={({ isActive }) =>
            isActive ? "act" : undefined
          }>List of Customers</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/sales" className={({ isActive }) =>
            isActive ? "act" : undefined
          }>Customer Sales Order</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/CustomerCategories" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Customer Categories</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/CustomerCredit" className={({ isActive }) =>
            isActive ? "act" : undefined
          }>Customer Credit Notes</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/CustomerReceipt" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Customer Receipt</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/ListSales" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>List of Sales Reps</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/ProcessReceipt" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Process Receipt</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/CustomerWrite" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Customer Write-Offs</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/NewCustomerCategory" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>New Customer Category</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/invoice" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Cutomer Invoice</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/RecurringInvoice" className={({ isActive }) =>
              isActive ? "act" : undefined
          }> Recurring Invoice</NavLink>
        </div>

     
      </NavIteams>

      <NavIteams title="Vendors">
        <div className="makeSpacing">
          <NavLink to="/VendorDashboard" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Vendor Dashboard</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/CreateNewVendor" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Create New Vendor</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/VendorAdjustments" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Vendor Adjustments</NavLink>
        </div>

        <div className="makeSpacing">
          <NavLink to="/ListVendors" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>List of Vendors</NavLink>
        </div>

        <div className="makeSpacing">
          <NavLink to="/CustomerSalesOrder" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Customer Sales Order</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/VendorCategories" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Vendor Categories</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/VendorPurchaseOrders" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Vendor Purchase Orders</NavLink>
        </div>

        <div className="makeSpacing">
          <NavLink to="/VendorInvoices" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Vendor Invoices</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/VendorReturns" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Vendor Returns</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/VendorPayment" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Vendor Payment</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/ProcessPayment" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Process Payment</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/AdjustVendorOpeningBalances" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Adjust Vendor Opening Balances</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/NewVendorCategory" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>New Vendor Category</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/ProcessVendorInvoice" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Process Vendor Invoice</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/ProcessVendorReturn" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Process Vendor Return</NavLink>
        </div>

        <div className="makeSpacing">
          <NavLink to="/ProcessVendorAdjustment" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Process Vendor Adjustment</NavLink>
        </div>

      </NavIteams>

      <NavIteams title="Items">
        <div className="makeSpacing">
          <NavLink to="/ItemDashboard" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Item Dashboard</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/CreateNewItem" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Create New Item</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/CreateItemUnchecked" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Create Item Unchecked</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/CreateItemChecked" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Create Item Checked</NavLink>
        </div>

        <div className="makeSpacing">
          <NavLink to="/ItemDashboardCat" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Item Categories</NavLink>
        </div>

        <div className="makeSpacing">
          <NavLink to="/ItemDashboarList" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>List of Item</NavLink>
        </div>

        <div className="makeSpacing">
          <NavLink to="/ListItemBundles" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>List of Item Bundles Dashboard</NavLink>
        </div>

        <div className="makeSpacing">
          <NavLink to="/AdjustItemOpeningBalances" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>List of Item Bundles</NavLink>
        </div>

        <div className="makeSpacing">
          <NavLink to="/ItemBundle" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Item Bundle</NavLink>
        </div>

        <div className="makeSpacing">
          <NavLink to="/CreateInventoryAdjustment" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Create Inventory Adjustment</NavLink>
        </div>

        <div className="makeSpacing">
          <NavLink to="/ItemReceipt" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Item Receipt</NavLink>
        </div>

        <div className="makeSpacing">
          <NavLink to="/ListOfAccount" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>List ofAccounts</NavLink>
        </div>

        <div className="makeSpacing">
          <NavLink to="/CreateAnAccount" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Create an Account</NavLink>
        </div>



      </NavIteams>

      <NavIteams title="Chart of accounts">
        <div className="makeSpacing">
          <NavLink to="/CreateAnAccount" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Create an Account</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/MakeJournalEntries" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Make Journal Entries</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/TrialBalance" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Trial Balance</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/ListOfAccount" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>List Of Account</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/ChartOfAccountExpenses" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Chart Of Account Expenses</NavLink>
        </div>


      </NavIteams>

      <NavIteams title="Banking">
        <div className="makeSpacing">
          <NavLink to="/CreatePaymentVoucher" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Create Payment Voucher</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/MakeDeposit" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Make Deposit</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/TransferFunds" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Transfer Funds</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/CreateBatchEntryBills" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Create Batch Entry - Bills</NavLink>
        </div>

      </NavIteams>

      <NavIteams title="Employee">
        <div className="makeSpacing">
          <NavLink to="/CreateNewEmployee" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Create New Employee</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/ListOfEmployee" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>List of Employees</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/EmployeeTimeSheet" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Employee/Job Time Sheet</NavLink>
        </div>
        <div className="makeSpacing">
          <NavLink to="/CreateSalarySructure" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Create Salary Structure</NavLink>
        </div>

        <div className="makeSpacing">
          <NavLink to="/CreateSructure" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Create Structure</NavLink>
        </div>


      </NavIteams>

      <NavIteams title="HR/Payroll">
        <div className="makeSpacing">
          <NavLink to="/HrPage" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>HR/Payroll Dashboard</NavLink>
        </div>


      </NavIteams>

      <NavIteams title="Financial">
        <div className="makeSpacing">
          <NavLink to="/BudgetPage" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>Budget Page</NavLink>
        </div>


      </NavIteams>

      <NavIteams title="Reports">
        <div className="makeSpacing">
          <NavLink to="/Reports" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>All Reports</NavLink>
        </div>
      </NavIteams>

      <NavIteams title="Settings">
        <div className="makeSpacing">
          <NavLink to="/AccountingSettings" className={({ isActive }) =>
              isActive ? "act" : undefined
          }>All Reports</NavLink>
        </div>
      </NavIteams>
    </div>
  )
}


const NavIteams = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <div className="">
        <div className="d-flex">  <ArrowDropDownIcon/><h5 onClick={handleClick} style={{ cursor: "pointer" }}>{ props.title}</h5></div>
        {
          isOpen && <ul style={{ transition: "ease-in 2s" }} className="ul">
              <li style={{listStyle:"none"}}>
                {props.children}
              </li>
            </ul>
        }

      </div>
    </>
  )
}
export default MobileSideBar