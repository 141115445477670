import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
import '../styles/reports.scss'
import '../styles/generalTabel.scss'
import img from '../assets/Youtube video player.png'
const UnpaidBillsDetail = () => {
    return (
        <div>
            <UserNavbar />
            <div id="RealizedLosses">
                <div className="container repHolder">
                    <div className="header">
                        <div className="left">
                            <h1>Unpaid Bills Detail</h1>
                        </div>
                        <div className="right">
                            <button className="btnOne">Comment</button>
                            <button className="btnTwo">Email</button>
                            <button className="btnThrree">Print</button>
                            <button className="btnThrree">Download Report</button>
                            <button className="btnFour">Vendor Dashboard</button>
                        </div>
                    </div>
                    <div className="period">
                        <div className="d-flex" style={{
                            justifyContent: "space-between"
                        }}>
                            <div className="period">
                                <div className="left">
                                    <span>Date</span>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Custom
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item active" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>

                                    <input type="date" />
                                    <span>Past due</span>
                                    <input type="text" style={{ width: 30 }} placeholder="1" />
                                </div>
                                <div className="right">
                                    <span>Sort by</span>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Default
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item active" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <img src={img} alt="" style={{
                                width: 200,

                            }} />
                        </div>


                    </div>

                    <div className="table">
                        <table className="table" style={{ width: 1200 }}>
                            <thead>

                                <tr>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th scope="col">Num</th>
                                    <th>Terms</th>
                                    <th scope="col">Due Date</th>
                                    <th scope="col">Aging</th>
                                    <th>Open Balance</th>

                                </tr>
                            </thead>
                            <tr>
                                <td colSpan={7}>
                                    <h4>Vendor 1</h4>
                                </td>
                            </tr>
                            <tr>
                                <td>Invoice</td>
                                <td>21/02/2021</td>
                                <td>1</td>
                                <td>Deposit</td>
                                <td>11100 - Account...</td>
                                <td>211</td>
                                <td>100,320.00</td>
                            </tr>

                            <tr>
                                <td>Invoice</td>
                                <td>21/02/2021</td>
                                <td>1</td>
                                <td>Deposit</td>
                                <td>11100 - Account...</td>
                                <td>211</td>
                                <td>100,320.00</td>

                            </tr>

                            <tr>
                                <td>Invoice</td>
                                <td>21/02/2021</td>
                                <td>1</td>
                                <td>Deposit</td>
                                <td>11100 - Account...</td>
                                <td>211</td>
                                <td>100,320.00</td>
                            </tr>
                            <tr>
                                <td colSpan={6}><h4>Total Vendor 1</h4></td>
                                <td className="bd">100,320.00</td>
                            </tr>
                            <tr>
                                <td colSpan={7}>
                                    <h4>Vendor 2</h4>
                                </td>
                            </tr>
                            <tr>
                                <td>Invoice</td>
                                <td>21/02/2021</td>
                                <td>1</td>
                                <td>Deposit</td>
                                <td>11100 - Account...</td>
                                <td>211</td>
                                <td>100,320.00</td>
                            </tr>
                            <tr>
                                <td>Invoice</td>
                                <td>21/02/2021</td>
                                <td>1</td>
                                <td>Deposit</td>
                                <td>11100 - Account...</td>
                                <td>211</td>
                                <td>100,320.00</td>
                            </tr>
                            <tr>
                                <td>Invoice</td>
                                <td>21/02/2021</td>
                                <td>1</td>
                                <td>Deposit</td>
                                <td>11100 - Account...</td>
                                <td>211</td>
                                <td>100,320.00</td>
                            </tr>
                            <tr>
                                <td colSpan={6}><h4>Total Vendor 1</h4></td>
                                <td className="bd">100,320.00</td>
                            </tr>

                            <tbody>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <FooterBoard />
        </div>
    )
}


export default UnpaidBillsDetail