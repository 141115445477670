import UserNavbar from "../usersdashboard/UserNavbar";
import "../styles/reports.scss";
import "../styles/generalTabel.scss";
import { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";
import img from "../assets/Youtube video player.png";
import FooterBoard from "../dashboard/FooterBoard";
const PageFourPro = () => {
    const [toggle, setToggle] = useState(true);

    const tableData = [
        {
            name: "45000-Tithes/Offerings (Unrestricted contributions,...",
            ibadan: 200,
            lagos: 200,
            unclassified: 200,
            unclassified1: 200,
            unclassified2: 200,
            total: 200
        },
        {
            name: "45000-Tithes/Offerings (Unrestricted contributions,...",
            ibadan: 200,
            ibadan1: 200,
            lagos: 200,
            unclassified1: 200,
            unclassified2: 200,
            total: 200
        },
        {
            name: "Total Accounts Receivable",
            ibadan: 200,
            ibadan1: 200,
            lagos: 200,
            unclassified1: 200,
            unclassified2: 200,
            total: 200
        },

    ]
    return (
        <div>
            <UserNavbar />
            <div id="RealizedLosses">
                <div className="container repHolder">
                    <div className="header">
                        <div className="left">
                            <h1>Profit & Loss YTD Comparison</h1>
                        </div>
                        <div className="right">
                            <button className="btnOne">Comment</button>
                            <button className="btnTwo">Email</button>
                            <button className="btnThrree">Print</button>
                            <button className="btnThrree">Download Report</button>
                            <button className="btnFour">Budget Dashboard</button>
                        </div>
                    </div>
                    <div className="period">
                        <div
                            className="d-flex"
                            style={{
                                justifyContent: "space-between",
                            }}
                        >
                            <div className="period">
                                <div className="left">
                                    <span>Dates</span>
                                    <div className="dropdown">
                                        <button
                                            className="btn dropdown-toggle"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Custom
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a className="dropdown-item active" href="#">
                                                    Action
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    Another action
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    Something else here
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <span>As of</span>
                                    <input type="date" />

                                </div>
                                <div className="d-flex gap-2">

                                    <div className="right">
                                        <span>Show Columns</span>
                                        <div className="dropdown">
                                            <button
                                                className="btn dropdown-toggle"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Class
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <a className="dropdown-item active" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <span>Sort by</span>
                                        <div className="dropdown">
                                            <button
                                                className="btn dropdown-toggle"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Default
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <a className="dropdown-item active" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="">
                                <img
                                    src={img}
                                    alt=""
                                    style={{
                                        width: 200,
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div id='GeneralTable'>
                        <table class="table">
                            <thead>

                                <tr>
                                    <th scope="col" style={{
                                        width: "500px",
                                    }}></th>
                                    <th scope="col" colSpan={2} className="text-center">Taxable Sales</th>
                                    <th scope="col" colSpan={2} className="text-center">Total no. sales tax code</th>
                                    <th scope="col" colSpan={2} className="text-center">TOTAL</th>
                                </tr>
                                <tr>
                                    <th scope="col" style={{
                                        width: "500px",
                                    }}></th>
                                    <th scope="col">Dec 31, 21</th>
                                    <th scope="col">Dec 31, 20</th>
                                    <th scope="col">Dec 31, 21</th>
                                    <th scope="col">Dec 31, 20</th>
                                    <th scope="col">Dec 31, 21</th>
                                    <th scope="col">Dec 31, 20</th>
                                </tr>
                            </thead>
                            <tbody>

                                <NewTable title={"Ordinary Income/Expense"} _id={1}>
                                    <NewTable title={"Income"} _id={2}>


                                        {tableData.map(item => <TableItem data={item} />)}



                                    </NewTable>
                                </NewTable>
                                {/* newLine */}
                                <NewTable title={"Cost of Goods Sold"} >
                                    {tableData.map(item => <TableItem data={item} />)}
                                </NewTable>

                                {/* newLine */}
                                <tr>
                                    <td style={{
                                        paddingLeft: 40
                                    }}>Gross Profit</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>
                                <tr>
                                    <td style={{
                                        paddingLeft: 40
                                    }}>Net Ordinary Income</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>
                                <tr>
                                    <td style={{
                                        paddingLeft: 40
                                    }}>Net Income</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <FooterBoard />
        </div>
    );

};
const NewTable = (props) => {
    const [toggle, setToggle] = useState(true);
    const handleClick = () => {
        setToggle(!toggle);
    };
    const processPadding = () => {
        return (props._id * 12) + "px";
    }
    return (
        <>
            <tr>
                <td colspan="7" onClick={handleClick}>
                    <h6 style={{ paddingLeft: processPadding() }}><ArrowDropDownIcon /> {props.title}</h6>
                </td>
            </tr>
            {
                toggle && <>
                    {props.children}
                </>
            }
        </>
    )
}
const TableItem = (props) => {
    return <tr>
        {Object.values(props.data).map((value) => <td>{value}</td>)}
    </tr>
}

export default PageFourPro