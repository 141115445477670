import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
import '../styles/reports.scss'
import '../styles/generalTabel.scss'
import img from '../assets/Youtube video player.png'
const TrialBalances = () => {
  return (
    <div>
      <UserNavbar />
      <div id="RealizedLosses">
        <div className="container repHolder">
          <div className="header">
            <div className="left">
              <h1>Trial Balance</h1>
            </div>
            <div className="right">
              <button className="btnOne">Comment</button>
              <button className="btnTwo">Email</button>
              <button className="btnThrree">Print</button>
              <button className="btnThrree">Download Report</button>
              <button className="btnFour">Customer Dashboard</button>
            </div>
          </div>
          <div className="period">
            <div className="d-flex" style={{
              justifyContent: "space-between"
            }}>
              <div className="period">
                <div className="left">
                  <span>Dates</span>
                  <div className="dropdown">
                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Today
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item active" href="#">Action</a></li>
                      <li><a className="dropdown-item" href="#">Another action</a></li>
                      <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </div>
                  <span>From</span>
                  <input type="date" />
                  <span>To</span>
                  <input type="date" />
                </div>
                <div className="right">
                  <span>Sort by</span>
                  <div className="dropdown">
                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Default
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item active" href="#">Action</a></li>
                      <li><a className="dropdown-item" href="#">Another action</a></li>
                      <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <img src={img} alt="" style={{
                width: 200,

              }} />
            </div>


          </div>

          <div className="table">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan={6}></th>
                  <th scope="col" colSpan={6}>Oct  31, 22</th>
                </tr>
                <tr>
                  <th colSpan={6}></th>
                  <th scope="col">Debit</th>
                  <th scope="col">Credit</th>
                </tr>
              </thead>
              <tbody>
                <tr>

                </tr>

                <tr>
                  <td colSpan={6}>45000-Tithes/Offerings (Unrestricted contributions,...</td>
                  <td>200.00</td>
                  <td>200.00</td>
                  
                </tr>
                <tr>
                  <td colSpan={6}>45000-Tithes/Offerings (Unrestricted contributions,...</td>
                  <td>200.00</td>
                  <td>200.00</td>

                </tr>

                <tr>
                  <td colSpan={6}>45000-Tithes/Offerings (Unrestricted contributions,...</td>
                  <td>200.00</td>
                  <td>200.00</td>

                </tr>

                {/* newLine */}


                <tr>
                  <td colspan="6">Net Income</td>

                 
                  <td style={{
                    borderTop: '2px solid black'
                  }}>200</td>
                  <td style={{
                    borderTop: '2px solid black'
                  }}>200</td>
                 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <FooterBoard />
    </div>
  )
}

export default TrialBalances