
import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
import '../styles/reports.scss'
import '../styles/generalTabel.scss'
import img from '../assets/Youtube video player.png'
const AgingDetail = () => {
  return (
    <div>
      <UserNavbar />
      <div id="RealizedLosses">
        <div className="container repHolder">
          <div className="header">
            <div className="left">
              <h1>A/R Aging Detail</h1>
            </div>
            <div className="right">
              <button className="btnOne">Comment</button>
              <button className="btnTwo">Email</button>
              <button className="btnThrree">Print</button>
              <button className="btnFour">Customer Dashboard</button>
            </div>
          </div>
          <div className="period">
            <div className="d-flex" style={{
              justifyContent: "space-between"
            }}>
              <div className="period">
                <div className="left">
                  <span>Dates</span>
                  <div className="dropdown">
                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Today
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item active" href="#">Action</a></li>
                      <li><a className="dropdown-item" href="#">Another action</a></li>
                      <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </div>
                  
                  <input type="date" />
                  <span>Interval (days)</span>
                  <input type="text" style={{width:40}} placeholder="30"/>
                </div>
                <div className="right">
                  <span>Sort by</span>
                  <div className="dropdown">
                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Default
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item active" href="#">Action</a></li>
                      <li><a className="dropdown-item" href="#">Another action</a></li>
                      <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <img src={img} alt="" style={{
                width: 200,

              }} />
            </div>


          </div>

          <div className="table">
            <table className="table">
              <thead>
                <tr>
                  <th >Type</th>
                  <th scope="col">Date</th>
                  <th scope="col">Num</th>
                  <th scope="col">P.O.#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Terms</th>
                  <th scope="col">Due Date</th>
                  <th scope="col">Class</th>
                  <th scope="col">Aging</th>
                  <th scope="col">Opening Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="10">
                    <h6>1-30</h6>
                  </td>
                </tr>
                <tr>
                  <td>Check</td>
                  <td>21/02/2021</td>
                  <td></td>
                  <td></td>
                  <td>Customer 1</td>
                  <td></td>
                  <td>08/01/21</td>
                  <td>Ibadan Class</td>
                  <td></td>
                  <td>100,320.00</td>
                </tr>
                <tr>
                  <td>Invoice</td>
                  <td>21/02/2021</td>
                  <td></td>
                  <td></td>
                  <td>Customer 1</td>
                  <td></td>
                  <td>08/01/21</td>
                  <td>Ibadan Class</td>
                  <td></td>
                  <td>100,320.00</td>
                </tr>
                <tr>
                  <td>Invoice</td>
                  <td>21/02/2021</td>
                  <td></td>
                  <td></td>
                  <td>Customer 1</td>
                  <td></td>
                  <td>08/01/21</td>
                  <td>Ibadan Class</td>
                  <td></td>
                  <td>100,320.00</td>
                </tr>
                <tr>
                  <td>Invoice</td>
                  <td>21/02/2021</td>
                  <td></td>
                  <td></td>
                  <td>Customer 1</td>
                  <td></td>
                  <td>08/01/21</td>
                  <td>Ibadan Class</td>
                  <td></td>
                  <td>100,320.00</td>
                </tr>

                <tr>
                  <td colspan="7">Total</td>
                  <td colSpan="2"></td>
                  <td style={{
                    borderTop: '2px solid black'
                  }}>100,320.00</td>
                </tr>

                {/* newLine */}
                <tr>
                  <td colspan="10">
                    <h6>31-60</h6>
                  </td>
                </tr>
                <tr>
                  <td>Invoice</td>
                  <td>21/02/2021</td>
                  <td></td>
                  <td></td>
                  <td>Customer 1</td>
                  <td></td>
                  <td>08/01/21</td>
                  <td>Ibadan Class</td>
                  <td></td>
                  <td>100,320.00</td>
                </tr>

                <tr>
                  <td>Invoice</td>
                  <td>21/02/2021</td>
                  <td></td>
                  <td></td>
                  <td>Customer 1</td>
                  <td></td>
                  <td>08/01/21</td>
                  <td>Ibadan Class</td>
                  <td></td>
                  <td>100,320.00</td>
                </tr>

                <tr>
                  <td>Invoice</td>
                  <td>21/02/2021</td>
                  <td></td>
                  <td></td>
                  <td>Customer 1</td>
                  <td></td>
                  <td>08/01/21</td>
                  <td>Ibadan Class</td>
                  <td></td>
                  <td>100,320.00</td>
                </tr>
                <tr>
                  <td colspan="7">Total</td>
                  <td colSpan="2"></td>
                  <td style={{
                    borderTop: '2px solid black'
                  }}>100,320.00</td>
                </tr>

                {/* newLine */}
                <tr>
                  <td colspan="10">
                    <h6>61-90</h6>
                  </td>
                </tr>
                <tr>
                  <td>Invoice</td>
                  <td>21/02/2021</td>
                  <td></td>
                  <td></td>
                  <td>Customer 1</td>
                  <td></td>
                  <td>08/01/21</td>
                  <td>Ibadan Class</td>
                  <td></td>
                  <td>100,320.00</td>
                </tr>

                <tr>
                  <td>Invoice</td>
                  <td>21/02/2021</td>
                  <td></td>
                  <td></td>
                  <td>Customer 1</td>
                  <td></td>
                  <td>08/01/21</td>
                  <td>Ibadan Class</td>
                  <td></td>
                  <td>100,320.00</td>
                </tr>

                <tr>
                  <td>Invoice</td>
                  <td>21/02/2021</td>
                  <td></td>
                  <td></td>
                  <td>Customer 1</td>
                  <td></td>
                  <td>08/01/21</td>
                  <td>Ibadan Class</td>
                  <td></td>
                  <td>100,320.00</td>
                </tr>
                <tr>
                  <td colspan="7">Total</td>
                  <td colSpan="2"></td>
                  <td style={{
                    borderTop: '2px solid black'
                  }}>100,320.00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <FooterBoard />
    </div>
  )
}
export default AgingDetail