import "../styles/addcustomer.scss";
import '../styles/inputs.scss'
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import Helper from "../component/Helper";
const CreateUserReUser = () => {
    const [formData, setFormData] = useState({
        email: '',
    });
    const navigate = useNavigate()
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (formData.email === '') {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'All fields are required'
            })
        } else if (!formData.email.includes('@')) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid email'
            })
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Password Reset',
                text: 'Password reset link has been sent to your email!'
            })
            navigate('/token')
        }
    };
  return (
      <div>
          <div id="ListUsers" className="">
              <Helper/>
       </div>
          <form action="">
              <div className="row" id="FormInput" >
                  <div className="col-md-6 right">
                      <div className="customerInfo d-flex gap-2">
                          <div className="select w-25 ">
                              <label className="mt-1">Title</label>
                              <select class="role" aria-label="Default select example">
                                  <option selected>Mr</option>
                                  <option value="1">Mrs</option>
                                  <option value="2">Miss</option>
                              </select>
                          </div>

                          <div>
                              <label htmlFor="">First Name</label>
                              <div>
                                  <input type="text" placeholder="Enter first name" value={formData.email} name="email" onChange={handleChange} />
                              </div>
                          </div>

                          <div>
                              <label htmlFor="">Last Name</label>
                              <div>
                                  <input type="text" placeholder="Enter last name" />
                              </div>
                          </div>

                      </div>

                      <div>
                          <label htmlFor="">Customer VAT Number</label>
                          <div>
                              <input type="text" placeholder="Customer VAT Number" />
                          </div>
                      </div>


                      <div className="select">
                          <label>Country</label>
                          <select class="role" aria-label="Default select example">
                              <option selected>Nigeria</option>
                              <option value="1">Nigeria</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                          </select>
                      </div>
                      <div className="btnHolder">
                          <button type="submit">Add new category</button>
                      </div>
                  </div>
                  <div className="col-md-6 left">

                      <div>
                          <label htmlFor="">Company Name <span>*</span></label>
                          <div>
                              <input type="text" placeholder="Enter your company name" />
                          </div>
                      </div>


                      <div className="check">
                          <div className="checking ">
                              <input type="checkbox" style={{ width: 20 }} />
                              <span>Same as billing address</span>
                          </div>

                          <div>
                              <label htmlFor="">Credit limit</label>
                              <div>
                                  <input type="text" placeholder="N0.00" />
                              </div>
                          </div>

                      </div>

                      <div className="select">
                          <label>Sales Rep </label>
                          <select class="role" aria-label="Default select example">
                              <option selected>Select sales rep</option>
                              <option value="1">Nigeria</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                          </select>
                      </div>
                      <div className="d-flex" style={{
                          justifyContent: "space-between"
                      }}>
                          <div >
                              <label htmlFor="">Email Adress</label>
                              <div>
                                  <input type="text" placeholder="Enter first name" value={formData.email} name="email" onChange={handleChange} />
                              </div>
                          </div>

                          <div>
                              <label htmlFor="">Tel No:</label>
                              <div>
                                  <input type="text" placeholder="Enter last name" />
                              </div>
                          </div>
                      </div>

                      <div className="btnHolder">
                          <button type="submit"><NavLink to="/AddSalesRep">Add new sales rep</NavLink></button>
                      </div>
                  </div>
              </div>
          </form>
    </div>
  )
}

export default CreateUserReUser