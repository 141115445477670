import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
import '../styles/reports.scss'
import '../styles/generalTabel.scss'
import img from '../assets/Youtube video player.png'
const AccountListing = () => {
    return (
        <div>
            <UserNavbar />
            <div id="RealizedLosses">
                <div className="container repHolder">
                    <div className="header">
                        <div className="left">
                            <h1>Account Listing</h1>
                        </div>
                        <div className="right">
                            <button className="btnOne">Comment</button>
                            <button className="btnTwo">Email</button>
                            <button className="btnThrree">Print</button>
                            <button className="btnThrree">Download Report</button>
                            <button className="btnFour">Budget Dashboard</button>
                        </div>
                    </div>
                    <div className="period">
                        <div className="d-flex" style={{
                            justifyContent: "space-between"
                        }}>
                            <div className="period">
                                <div className="left">
                                    <span>Sort by</span>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Default
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item active" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>

                                </div>

                            </div>
                            <img src={img} alt="" style={{
                                width: 200,

                            }} />
                        </div>


                    </div>

                    <div className="table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th >Account</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Currency</th>
                                    <th scope="col">Balance Total</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Account number</th>
                                    <th scope="col">Tax Line</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td>11100 - Account...</td>
                                    <td>Bank</td>
                                    <td>Sales EUR</td>
                                    <td>2,100.00</td>
                                    <td></td>
                                    <td>11100</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>11100 - Account...</td>
                                    <td>Bank</td>
                                    <td>Sales EUR</td>
                                    <td>2,100.00</td>
                                    <td></td>
                                    <td>11100</td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>11100 - Account...</td>
                                    <td>Bank</td>
                                    <td>Sales EUR</td>
                                    <td>2,100.00</td>
                                    <td></td>
                                    <td>11100</td>
                                    <td></td>
                                </tr>


                                <tr>
                                    <td>11100 - Account...</td>
                                    <td>Bank</td>
                                    <td>Sales EUR</td>
                                    <td>2,100.00</td>
                                    <td></td>
                                    <td>11100</td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>11100 - Account...</td>
                                    <td>Bank</td>
                                    <td>Sales EUR</td>
                                    <td>2,100.00</td>
                                    <td></td>
                                    <td>11100</td>
                                    <td></td>
                                </tr>

                            </tbody>
                            <thead />
                        </table>
                    </div>
                </div>
            </div>
            <FooterBoard />
        </div>
    )
}
export default AccountListing