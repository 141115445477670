import "../styles/addcustomer.scss";
import Footer from "../component/Footer";
import UserNavbar from "./UserNavbar";
import '../styles/inputs.scss'
import { Link } from "react-router-dom";
import CreateUserReUser from "./CreateUserReUser";
import ButtomNav from "../component/ButtomNav";
import React from "react";
const UserPayment = () => {
  return (
    <div>
      <UserNavbar />
      <div id="AddNewCustomer">
        <div className="container">
          <div className="formHolderContact">
            <div className="titleHolder">
              <h1>Create New Customer</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                vulputate libero et velit interdum, ac aliquet odio mattis.
              </p>
            </div>

           <CreateUserReUser/>
            <div className="address" id="FormInput">
              <div className="tab">
                <ul>
                  <li ><Link to='/add-customer'>Contact Details</Link></li>
                  <li><Link to='/UserAddress'>Address</Link></li>
                  <li><Link to='/UserAdditionalContact'>Additional Contact</Link></li>
                  <li className="tabActive"><Link to='/UserPayment'>Collections & Payment and Billing</Link></li>
                  <li><Link to='/UserNote'>Notes</Link></li>
                  <li><Link to='/Attachments'>Attachments</Link></li>
                </ul>
              </div>
              <div className="formHoldering">
                <div className="row addy">
                
                  <div className="paymentMethod">
                    <div className="row">
                      <div className="col-md-6 right">
                        <div className="dividedForm">
                          <div className="select">
                            <label htmlFor="">Preferred Payment Method</label>
                            <select
                              class="role"
                              aria-label="Default select example"
                            >
                              <option selected>Select payment method</option>
                              <option value="1">Nigeria</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div className="select">
                            <label htmlFor="">Preferred Delivery Method</label>
                            <select
                              class="role"
                              aria-label="Default select example"
                            >
                              <option selected>Select delivery method</option>
                              <option value="1">Nigeria</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="left col-md-6">
                        <div className="select">
                          <label htmlFor="">Select Currency</label>
                          <select
                              className="role"
                              aria-label="Default select example"
                          >
                            <option selected>Euro</option>
                            <option value="1">Nigeria</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                        <div className="select">
                          <label htmlFor="">Terms</label>
                          <select
                            class="role"
                            aria-label="Default select example"
                          >
                            <option selected>NET 15</option>
                            <option value="1">Nigeria</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                        <div className="dividedForm d-flex gap-2">
                          <div className="balance">
                            <label htmlFor="">Opening Balance</label>
                            <div className="">
                              <input type="text" placeholder="N0.00" />
                            </div>
                          </div>
                          <div className="balance">
                            <label htmlFor="">As of</label>
                            <div className="">
                              <input type="date" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ButtomNav/>
    </div>
  )
};

export default UserPayment