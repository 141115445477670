import UserNavbar from "../usersdashboard/UserNavbar"
import SideBar from "./SideBar"
import '../styles/AccountingSettings.scss'
import RigthBar from "./RigthBar"
const SalesCustomers = () => {
  return (
    <div>
      <UserNavbar />
      <div className="container-fluid">
        <h1 className="pt-3 pb-3">Settings</h1>
        <div className="split" id="AccountingSettings">
          <div className="sidebar">
            <SideBar />
          </div>

          <div className="main">
            <div className="boxHoldingSettings">

              <div className="boxex">
                <h4>Sales Forms</h4>
                <div className="check">
                  <span>Usual Shipping Method</span>
                  <div className="">
                    <select style={{
                      marginLeft: 0,
                      padding: 10,
                      width: '300px'
                    }}
                      aria-label="Default select example"
                    >
                      <option selected></option>
                      <option value="1">Nigeria</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>

                  </div>

                </div>
                <div className="check mt-2">
                  <span>Usual FOB</span>
                  <div className="">
                    <select style={{
                      marginLeft: 0,
                      padding: 10,
                      width: '300px'
                    }}
                      aria-label="Default select example"
                    >
                      <option selected></option>
                      <option value="1">Nigeria</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>

                  </div>

                </div>
                <div className="mt-2">
                  <input type="checkbox" defaultChecked="checked" name="" id="" />
                  <span className="center">Warn about duplicate invoice numbers</span>
                </div>
              </div>

              <div className="boxex">

                <h4>Sales Orders</h4>

                <div className="mt-2">
                  <input type="checkbox" defaultChecked="checked" name="" id="" />
                  <span className="mt-2">Enable Sales Orders</span>
                  <div className="check mt-2 mx-3" >

                  </div>
                  <div className="mx-3 mt-1">
                    <input type="radio" defaultChecked="checked" name="" id="" />
                    <span className="center">Don’t print items with zero amounts when converting to Invoice</span>
                  </div>

                  <div className="mx-3 mt-1">
                    <input type="radio" defaultChecked="" name="" id="" />
                    <span className="center">Don’t allow negative quantities</span>
                  </div>
                </div>

                <div className="mt-2">
                  <p>Choose template for:</p>
                  <div className="check">
                    <span>Packing Slip:</span>
                    <div className="">
                      <select style={{
                        marginLeft: 0,
                        padding: 10,
                        width: '300px'
                      }}
                        aria-label="Default select example"
                      >
                        <option selected></option>
                        <option value="1">Nigeria</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>

                    </div>

                  </div>
                  <div className="check mt-2">
                    <span>Pick List:</span>
                    <div className="">
                      <select style={{
                        marginLeft: 0,
                        padding: 10,
                        width: '300px'
                      }}
                        aria-label="Default select example"
                      >
                        <option selected></option>
                        <option value="1">Nigeria</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>

                    </div>

                  </div>
                </div>
              </div>

              <div className="boxex">
                <h4>Miscellaneous</h4>
                <div className="">
                  <span>Choose template for invoice packing slip:</span>
                  <div className="">
                    <select style={{
                      marginLeft: 0,
                      padding: 10,
                      width: '300px'
                    }}
                      aria-label="Default select example"
                    >
                      <option selected>Default</option>
                      <option value="1">Nigeria</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>

                  </div>

                </div>

              </div>
              <div className="d-flex gap-2">
                <div className="boxex">
                  <h4>Collection Center</h4>
                  <div className="d-flex gap-2">
                    <input type="checkbox" name="" id="" />
                    <span>Enable Collections Center</span>
                  </div>
                </div>
                <div className="boxex">
                  <h4>Overdue Customers</h4>
                  <div className="d-flex gap-2">
                    <input type="checkbox" name="" id="" />
                    <span>Do not sell to overdue customers</span>
                  </div>
                </div>
              </div>
              <div className="boxex">
                <h4>Custom Pricing</h4>
                <div className="d-flex gap-2">
                  <input type="radio" />
                  <span>No Custom Pricing</span>
                </div>
                <div className="d-flex gap-2">
                  <input type="radio" />
                  <span>Enable Price Levels</span>
                </div>
              </div>
            </div>

          </div>
          <div className="extra">
            <RigthBar />
          </div>
        </div>
      </div>

    </div>
  )
}

export default SalesCustomers