import UserNavbar from "./UserNavbar"
import '../styles/userHome.scss'
const UserHomePage = () => {
  return (
      <div id='UserHomePage'>
      <UserNavbar />
      
      </div>
  )
}

export default UserHomePage