import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
import '../styles/reports.scss'
import '../styles/generalTabel.scss'
import img from '../assets/Youtube video player.png'
const OpenSalesOrdersbyCustomer = () => {
  return (
    <div>
      <UserNavbar />
      <div id="RealizedLosses">
        <div className="container repHolder">
          <div className="header">
            <div className="left">
              <h1 style={{ fontSize: 25 }}>Open Sales Orders by Item</h1>
            </div>
            <div className="right">
              <button className="btnOne">Comment</button>
              <button className="btnTwo">Email</button>
              <button className="btnThrree">Print</button>
              <button className="btnThrree">Download Report</button>
              <button className="btnFour">Customer Dashboard</button>
            </div>
          </div>
          <div className="period">
            <div className="d-flex" style={{
              justifyContent: "space-between"
            }}>
              <div className="period">
                <div className="left">
                  <span>Date</span>
                  <div className="dropdown">
                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Custom
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item active" href="#">Action</a></li>
                      <li><a className="dropdown-item" href="#">Another action</a></li>
                      <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </div>
                  <span>From</span>
                  <input type="date" />
                  <span>To</span>
                  <input type="date" />
                </div>
                <div className="right">
                  <span>Sort by</span>
                  <div className="dropdown">
                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Default
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item active" href="#">Action</a></li>
                      <li><a className="dropdown-item" href="#">Another action</a></li>
                      <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <img src={img} alt="" style={{
                width: 200,

              }} />
            </div>


          </div>

          <div className="table">
            <table className="table">
              <thead>

                <tr>
                  <th>Type</th>
                  <th scope="col">Date</th>
                  <th scope="col">Due Date</th>
                  <th scope="col">Num</th>
                  <th scope="col">Name</th>
                  <th scope="col">Qty</th>
                  <th>Invoiced</th>
                  <th>Backordered</th>
                  <th>U/M</th>
                  <th>Amount</th>
                  <th>Open Balance</th>
                </tr>
              </thead>
              <tr>
                <td colSpan={11}><h4>Asembly</h4></td>
              </tr>
              <tr>
                <td colSpan={11}><h4>Item Build</h4></td>
              </tr>
              <tr>
                <td>Sales Order</td>
                <td>21/02/2021</td>
                <td>21/02/2021</td>
                <td>1</td>
                <td>Customer 1</td>
                <td>76</td>
                <td>0</td>
                <td>76</td>
                <td>hr</td>
                <td>100,320.00</td>
                <td>100,320.00</td>
              </tr>

              <tr>
                <td colSpan={5}>Total Item Builds</td>
                <td className="bd">76</td>
                <td className="bd">0</td>
                <td className="bd">76</td>
                <td></td>
                <td className="bd">100,320.00</td>
                <td className="bd">100,320.00</td>
              </tr>
              <tr>
                <td colSpan={5}>Total Assembly</td>
                <td className="bd">76</td>
                <td className="bd">0</td>
                <td className="bd">76</td>
                <td></td>
                <td className="bd">100,320.00</td>
                <td className="bd">100,320.00</td>
              </tr>

              <tr>
                <td colSpan={11}><h4>Item Build</h4></td>
              </tr>
              <tr>
                <td>Sales Order</td>
                <td>21/02/2021</td>
                <td>21/02/2021</td>
                <td>1</td>
                <td>Customer 1</td>
                <td>76</td>
                <td>0</td>
                <td>76</td>
                <td>hr</td>
                <td>100,320.00</td>
                <td>100,320.00</td>
              </tr>

              <tr>
                <td colSpan={5}>Total Item Builds</td>
                <td className="bd">76</td>
                <td className="bd">0</td>
                <td className="bd">76</td>
                <td></td>
                <td className="bd">100,320.00</td>
                <td className="bd">100,320.00</td>
              </tr>
              <tr>
                <td colSpan={5}>Total Assembly</td>
                <td className="bd">76</td>
                <td className="bd">0</td>
                <td className="bd">76</td>
                <td></td>
                <td className="bd">100,320.00</td>
                <td className="bd">100,320.00</td>
              </tr>

              <tr>
                <td colSpan={11}><h4>Item Build</h4></td>
              </tr>
              <tr>
                <td>Sales Order</td>
                <td>21/02/2021</td>
                <td>21/02/2021</td>
                <td>1</td>
                <td>Customer 1</td>
                <td>76</td>
                <td>0</td>
                <td>76</td>
                <td>hr</td>
                <td>100,320.00</td>
                <td>100,320.00</td>
              </tr>

              <tr>
                <td colSpan={5}>Total Item Builds</td>
                <td className="bd">76</td>
                <td className="bd">0</td>
                <td className="bd">76</td>
                <td></td>
                <td className="bd">100,320.00</td>
                <td className="bd">100,320.00</td>
              </tr>
              <tr>
                <td colSpan={5}>Total Assembly</td>
                <td className="bd">76</td>
                <td className="bd">0</td>
                <td className="bd">76</td>
                <td></td>
                <td className="bd">100,320.00</td>
                <td className="bd">100,320.00</td>
              </tr>

              <tr>
                <td colSpan={11}><h4>Item Build</h4></td>
              </tr>
              <tr>
                <td>Sales Order</td>
                <td>21/02/2021</td>
                <td>21/02/2021</td>
                <td>1</td>
                <td>Customer 1</td>
                <td>76</td>
                <td>0</td>
                <td>76</td>
                <td>hr</td>
                <td>100,320.00</td>
                <td>100,320.00</td>
              </tr>

              <tr>
                <td colSpan={5}>Total Item Builds</td>
                <td className="bd">76</td>
                <td className="bd">0</td>
                <td className="bd">76</td>
                <td></td>
                <td className="bd">100,320.00</td>
                <td className="bd">100,320.00</td>
              </tr>
              <tr>
                <td colSpan={5}>Total Assembly</td>
                <td className="bd">76</td>
                <td className="bd">0</td>
                <td className="bd">76</td>
                <td></td>
                <td className="bd">100,320.00</td>
                <td className="bd">100,320.00</td>
              </tr>


              <tr>
                <td colSpan={5}>
                  TOTAL
                </td>
                <td className="bd">76</td>
                <td className="bd">0</td>
                <td className="bd">76</td>
                <td></td>
                <td className="bd">100,320.00</td>
                <td className="bd">100,320.00</td>
              </tr>

              <tbody>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <FooterBoard />
    </div>
  )
}

export default OpenSalesOrdersbyCustomer