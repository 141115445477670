import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
import '../styles/reports.scss'
import '../styles/generalTabel.scss'
import img from '../assets/Youtube video player.png'
const Equity = () => {
    return (
        <div>
            <UserNavbar />
            <div id="RealizedLosses">
                <div className="container repHolder">
                    <div className="header">
                        <div className="left">
                            <h1>Equity</h1>
                        </div>
                        <div className="right">
                            <button className="btnOne">Comment</button>
                            <button className="btnTwo">Email</button>
                            <button className="btnThrree">Print</button>
                            <button className="btnThrree">Download Report</button>
                            <button className="btnFour">Dashboard</button>
                        </div>
                    </div>
                    <div className="period">
                        <div className="d-flex" style={{
                            justifyContent: "space-between"
                        }}>
                            <div className="period">
                                <div className="left">
                                    <span>Dates</span>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Today
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item active" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>
                                    <span>From</span>
                                    <input type="date" />
                                    <span>To</span>
                                    <input type="date" />
                                </div>
                                <div className="right">
                                    <span>Sort by</span>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Default
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item active" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <img src={img} alt="" style={{
                                width: 200,

                            }} />
                        </div>


                    </div>

                    <div className="table">
                        <table className="table" >
                            <thead>
                               
                                <tr>
                                    <th style={{width:"40%"}}></th>
                                    <th scope="col">Ordinary Share Capital</th>
                                    <th scope="col">Retained Earnings</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>000</td>
                                    <td>000</td>
                                    <td>000</td>

                                </tr>
                                <tr>
                                    <td >Opening Balance 1/1/14</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>

                                <tr>
                                    <td>Profit for the year</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>

                                <tr>
                                    <td>Transactions with owners</td>
                                    <td>000</td>
                                    <td>000</td>
                                    <td>000</td>

                                </tr>
                                <tr>
                                    <td >Closing Balance 31/12/14</td>
                                    <td className="bd">200.00</td>
                                    <td className="bd">200.00</td>
                                    <td className="bd">200.00</td>
                                </tr>

                                <tr>
                                    <td>Opening Balance1/1/15</td>
                                    <td className="bd">200.00</td>
                                    <td className="bd">200.00</td>
                                    <td className="bd">200.00</td>
                                </tr>

                            
                              


                                {/* newLine */}

                                <tr>
                                    <td>Profit for the year</td>
                                    <td>000</td>
                                    <td>000</td>
                                    <td>000</td>

                                </tr>
                                <tr>
                                    <td >Opening Balance 1/1/14</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>

                                <tr>
                                    <td>Profit for the year</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>

                                <tr>
                                    <td>Transactions with owners</td>
                                    <td>000</td>
                                    <td>000</td>
                                    <td>000</td>

                                </tr>
                                <tr>
                                    <td >Closing Balance 31/12/14</td>
                                    <td className="bd">200.00</td>
                                    <td className="bd">200.00</td>
                                    <td className="bd">200.00</td>
                                </tr>

                                <tr>
                                    <td>Opening Balance1/1/15</td>
                                    <td className="bd">200.00</td>
                                    <td className="bd">200.00</td>
                                    <td className="bd">200.00</td>
                                </tr>

                             
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <FooterBoard />
        </div>
    )
}

export default Equity