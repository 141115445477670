import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
import '../styles/reports.scss'
import '../styles/generalTabel.scss'
import img from '../assets/Youtube video player.png'
const ItemListing = () => {
  return (
    <div>
      <UserNavbar />
      <div id="RealizedLosses">
        <div className="container repHolder">
          <div className="header">
            <div className="left">
              <h1>Item Listing</h1>
            </div>
            <div className="right">
              <button className="btnOne">Comment</button>
              <button className="btnTwo">Email</button>
              <button className="btnThrree">Print</button>
              <button className="btnThrree">Download Report</button>
              <button className="btnFour">Item Dashboard</button>
            </div>
          </div>
          <div className="period">
            <div className="d-flex" style={{
              justifyContent: "space-between"
            }}>
              <div className="period">
                <div className="d-flex" style={{
                  justifyContent: "space-between"
                }}>
                  <div className="period">
                    <div className="left">
                      <span>Sort by</span>
                      <div className="dropdown">
                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Default
                        </button>
                        <ul className="dropdown-menu">
                          <li><a className="dropdown-item active" href="#">Action</a></li>
                          <li><a className="dropdown-item" href="#">Another action</a></li>
                          <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                      </div>
                      
                    </div>

                  </div>

                </div>


              </div>
              <img src={img} alt="" style={{
                width: 300,

              }} />
            </div>


          </div>

          <div className="table" style={{

          }}>
            <table className="table" style={{
              width: '1500px'
            }} >
              <thead>

                <tr>
                  <th>Items</th>
                  <th >Description</th>
                  <th scope="col">Type</th>
                  <th scope="col">Cost</th>
                  <th scope="col">Price</th>
                  <th scope="col">Sales Tax Code</th>
                  <th scope="col">Quantity On Hand</th>
                  <th scope="col">Quantity On Sales Order</th>
                  <th scope="col">Reorder Pt (Min)</th>
                  <th scope="col">Quantity On Purchase Order</th>
                  <th>U/M Set</th>
                  <th>U/M</th>
                  <th>Preferred Vendror</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                
                </tr>
                <tr>
                  <td>InvenListTop</td>
                  <td></td>
                  <td>2</td>
                  <td>12</td>
                  <td>5,673</td>
                  <td>0</td>
                  <td>0</td>
                  <td>1,567</td>
                  <td></td>
                  <td></td>
                  <td>0</td>
                  <td>0</td>
                  <td></td>
                
                </tr>
                <tr>
                  <td>InvenListTop</td>
                  <td></td>
                  <td>2</td>
                  <td>12</td>
                  <td>5,673</td>
                  <td>0</td>
                  <td>0</td>
                  <td>1,567</td>
                  <td></td>
                  <td></td>
                  <td>0</td>
                  <td>0</td>
                  <td></td>
                 
                </tr>
               
                <tr>
                  <td>Item Building</td>
                  <td></td>
                  <td>2</td>
                  <td>12</td>
                  <td>5,673</td>
                  <td>0</td>
                  <td>0</td>
                  <td>1,567</td>
                  <td></td>
                  <td></td>
                  <td>0</td>
                  <td>0</td>
                  <td></td>
                 
                </tr>

                <tr>
                  <td>Item Bud</td>
                  <td></td>
                  <td>2</td>
                  <td>12</td>
                  <td>5,673</td>
                  <td>0</td>
                  <td>0</td>
                  <td>1,567</td>
                  <td></td>
                  <td></td>
                  <td>0</td>
                  <td>0</td>
                  <td></td>
                 
                </tr>

              </tbody>
              <thead />
            </table>
          </div>
        </div>
      </div>
      <FooterBoard />
    </div>
  )
}

export default ItemListing