import '../styles/generalTabel.scss'
import '../styles/reports.scss'
import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
const FinancialPositionFormat = () => {
    return (
        <div>
            <UserNavbar />
            <div id="RealizedLosses">
                <div className="container repHolder">
                    <div className="titleFormat">
                        <h1>Immaculate Metircs Plus Plc.</h1>
                        <p>Statement of financial position as at December 31,2015</p>
                    </div>

                    <div className="table">
                        <table className="table" style={{ width: '60%', margin: '0 auto' }}>
                            <thead>

                                <tr>
                                    <th style={{ width: 350 }}></th>
                                    <th></th>
                                    <th scope="col">2015</th>
                                    <th>2014</th>

                                </tr>
                            </thead>
                            <tr>
                                <td colSpan={4} className="text-center">
                                    Notes
                                </td>

                            </tr>
                            <tr>
                                <td colSpan={4} style={{ fontWeight: 500 }}>
                                    Non-current Assets:
                                </td>
                            </tr>
                            <tr>
                                <td>Property,plant & Equipment</td>
                                <td>1</td>
                                <td>200.00</td>
                                <td>200.00</td>
                            </tr>


                            <tr>
                                <td>Intangible Asset</td>
                                <td>1</td>
                                <td>200.00</td>
                                <td>200.00</td>
                            </tr>
                            <tr>
                                <td colSpan={2}></td>
                                <td className="bd">200.00</td>
                                <td className="bd">200.00</td>
                            </tr>

                            <tr>
                                <td colSpan={2}>Other Asset</td>
                                <td className="bd"></td>
                                <td className="bd">200.00</td>
                            </tr>

                            {/* newLine */}

                            <tr>
                                <td colSpan={4} style={{ fontWeight: 500 }}>
                                    Current Assets:
                                </td>
                            </tr>
                            <tr>
                                <td>Property,plant & Equipment</td>
                                <td>1</td>
                                <td>200.00</td>
                                <td>200.00</td>
                            </tr>


                            <tr>
                                <td>Intangible Asset</td>
                                <td>1</td>
                                <td>200.00</td>
                                <td>200.00</td>
                            </tr>
                            <tr>
                                <td colSpan={2}></td>
                                <td className="bd">200.00</td>
                                <td className="bd">200.00</td>
                            </tr>



                            {/* newLine */}

                            <tr>
                                <td colSpan={4} style={{ fontWeight: 500 }}>
                                    Current Liabilities:
                                </td>
                            </tr>
                            <tr>
                                <td>Property,plant & Equipment</td>
                                <td>1</td>
                                <td>200.00</td>
                                <td>200.00</td>
                            </tr>


                            <tr>
                                <td>Intangible Asset</td>
                                <td>1</td>
                                <td>200.00</td>
                                <td>200.00</td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ fontWeight: 400 }}>Net Current Assets</td>
                                <td className="bd">200.00</td>
                                <td className="bd">200.00</td>
                            </tr>

                            <tr>
                                <td colSpan={2} style={{ fontWeight: 400 }}>Non-current Liabilities:</td>
                                <td className="bd">200</td>
                                <td className="bd">200.00</td>
                            </tr>

                            {/* newLine */}

                            <tr>
                                <td colSpan={4} style={{ fontWeight: 500 }}>
                                    Equity:
                                </td>
                            </tr>
                            <tr>
                                <td>Property,plant & Equipment</td>
                                <td>1</td>
                                <td>200.00</td>
                                <td>200.00</td>
                            </tr>


                            <tr>
                                <td>Intangible Asset</td>
                                <td>1</td>
                                <td>200.00</td>
                                <td>200.00</td>
                            </tr>
                            <tr>
                                <td colSpan={2}></td>
                                <td className="bd">200.00</td>
                                <td className="bd">200.00</td>
                            </tr>


                            <tbody>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <FooterBoard />
        </div>
    )
}

export default FinancialPositionFormat