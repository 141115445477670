import "../styles/addcustomer.scss";
import Footer from "../component/Footer";
import UserNavbar from "./UserNavbar";
import '../styles/inputs.scss'
import FormInput from "../component/FormInput";
import imgIcon from "../assets/fluent_folder-48-filled.png";
import { Link } from "react-router-dom";
import CreateUserReUser from "./CreateUserReUser";
import ButtomNav from "../component/ButtomNav";
const UserAddress = () => {
  return (
    <div>
      <UserNavbar />
      <div id="AddNewCustomer">
        <div className="container">
          <div className="formHolderContact">
            <div className="titleHolder">
              <h1>Create New Customer</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                vulputate libero et velit interdum, ac aliquet odio mattis.
              </p>
            </div>

            <CreateUserReUser />
            <div className="address" id="FormInput">
              <div className="tab">
                <ul>
                  <li ><Link to='/add-customer'>Contact Details</Link></li>
                  <li className="tabActive"><Link to='/UserAddress'>Address</Link></li>
                  <li><Link to='/UserAdditionalContact'>Additional Contact</Link></li>
                  <li className=""><Link to='/UserPayment'>Collections & Payment and Billing</Link></li>
                  <li><Link to='/UserNote'>Notes</Link></li>
                  <li><Link to='/Attachments'>Attachments</Link></li>
                </ul>
              </div>
              <div className="formHoldering">
                <div className="row addy">
                 
                  <div className="contact">
                    <div className="row">
                      <div className="col-md-6 right contact">
                        <div>
                          <label htmlFor="">Billing Address</label>
                          <div>
                            <input type="text" placeholder="Street" />
                          </div>
                        </div>

                        <div className="dividedForm">
                          <div>
                            <input type="text" placeholder="City/Town" />
                          </div>
                          <div>
                            <input type="text" placeholder="State/Province" />
                          </div>

                        </div>
                        <div className="dividedForm">
                          <input type="text" placeholder="City/Town" />
                          <div className="select">
                            <select
                              class="role"
                              aria-label="Default select example"
                            >
                              <option selected>Nigeria</option>
                              <option value="1">Nigeria</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                        </div>

                      </div>

                      <div className="col-md-6 right contact">
                        <div>
                          <label htmlFor="">Shipping Address</label>
                          <div>
                            <input type="text" placeholder="Street" />
                          </div>
                        </div>

                        <div className="dividedForm">
                          <div>
                            <input type="text" placeholder="City/Town" />
                          </div>
                          <div>
                            <input type="text" placeholder="State/Province" />
                          </div>

                        </div>
                        <div className="dividedForm">
                          <input type="text" placeholder="City/Town" />
                          <div className="select">
                            <select
                              class="role"
                              aria-label="Default select example"
                            >
                              <option selected>Nigeria</option>
                              <option value="1">Nigeria</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ButtomNav />
    </div>
  )
};

export default UserAddress