import '../styles/customerDashboard.scss'
import UserNavbar from "./UserNavbar";
import SettingsIcon from '@mui/icons-material/Settings';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import CustomerTable from '../component/CustomerTable';
import GeneralNavBar from "../component/GeneralNavBar";
import Helper from '../component/Helper';

const CustomersDashBoard = () => {

  return (
    <div>
      <UserNavbar />
      <div id="CustomersDashBoard">
        <div className='container'>
       
          <div className="fsLayer">
            <h1 className='mt-3'>Customer Dashboard</h1>
            <NavItem />

          </div>
          <div>
            <div id="ListUsers" className="">
              <Helper />
            </div>
            </div>
          <div className="secondLayer mt-5">
            <div className="row">
              <div className="col-md-6 left">
                <div className="heading">
                  <h1>Top Customers</h1>
                  <div className="setting">
                    <span>Sort by:</span>
                    <div class="dropdown">
                      <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Balance
                      </button>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item active" href="#">Action</a></li>
                        <li><a class="dropdown-item" href="#">Another action</a></li>
                        <li><a class="dropdown-item" href="#">Something else here</a></li>

                      </ul>
                    </div>
                    <SettingsIcon />
                    <SwapVertIcon />
                  </div>
                </div>
                <div className="table mt-2">
                  <CustomerTable />
                </div>
              </div>
              <div className="col-md-6 right">
                <div className="innerHolder">
                  <div className="heading">
                    <h1>All Customers Report </h1>
                  </div>
                  <div className="sec-one d-flex  mt-4">
                    <p className='one'>List of customers</p>
                    <p>This lists customers details.</p>
                  </div>
                  <div className="secTwo">
                    <div className="title">
                      <h1>Customer Transaction Reports</h1>
                    </div>
                    <div className="para">
                      <p>Sales by Customer</p>
                      <p>Sales by Customer</p>
                    </div>
                    <div className="para">
                      <p className='one'>Customer Transactions</p>
                      <p >A list of all transactions for customers e.g. Invoices, receipts.</p>
                    </div>
                  </div>

                  <div className="secTwo">
                    <div className="title">
                      <h1>Customer Balances and Statements</h1>
                    </div>
                    <div className="para">
                      <p>Days Outstanding</p>
                      <p>How many days a customer’s balance has been outstanding.</p>
                    </div>
                    <div className="para">
                      <p className='one'>Statement Run</p>
                      <p >Generate and distribute statemnets to all customers</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tabling mt-2  lefts">
            <div className="heading">
              <h1>Customer Balances - Days Outstanding</h1>
              <div className="setting">
                <span>Sort by:</span>
                <div class="dropdown">
                  <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Balance
                  </button>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item active" href="#">Action</a></li>
                    <li><a class="dropdown-item" href="#">Another action</a></li>
                    <li><a class="dropdown-item" href="#">Something else here</a></li>

                  </ul>
                </div>
                <SettingsIcon />
                <SwapVertIcon />
              </div>
            </div>
            <CustomerTable />
          </div>
        </div>
      </div>

    </div>
  )
}

const NavItem = () => {
  return (
    <div className="d-flex navHolder">
      <GeneralNavBar name='Customer Dashboard' to='/csdasboard' />
      <GeneralNavBar name='Add New Customer' to='/add-customer' />
      <GeneralNavBar name='List of Customers' to='/list' />
      <GeneralNavBar name='Customer Sales Order' to='/sales' />
      <GeneralNavBar name='Customer Categories' to='/CustomerCategories' />
      <GeneralNavBar name='Customer Credit Notes' to='/CustomerCredit' />
      <GeneralNavBar name='Customer Receipt' to='/CustomerReceipt' />
      <GeneralNavBar name='List of Sales Reps' to='/ListSales' />
      <GeneralNavBar name='Create Invoice' to='/invoice' />
      <GeneralNavBar name='Process Receipt' to='/ProcessReceipt' />
      <GeneralNavBar name='Customer Write-Offs' to='/CustomerWrite' />
      <GeneralNavBar name='Recurring Invoice' to='/RecurringInvoice' />

    </div>
  )
}
export default CustomersDashBoard 