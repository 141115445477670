import "../styles/nav.scss";
import { Link, NavLink } from "react-router-dom";
import img from "../assets/Logo.png";
const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <Link className="navbar-brand">
          <div className="LogoHolder">
            <img src={img} alt="" />
          </div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="me-auto d-flex mb-2 mb-lg-0">
            <NavbarItem to="/" name="Home" />
            <NavbarItem to="/features" name="Features" />
            <NavbarItem to="/partners" name="Partners" />
            <NavbarItem to="/price" name="Pricing" />
            <NavbarItem to="/contact" name=" Contact us" />
            <NavbarItem to="/support" name="Client’s support" />
            <NavbarItem to="/online" name="Online Accountant" />
          </div>
        
          

          <div className="d-flex last">
           
            <Link to="/login" className="nav-link" style={{
              border: "1px solid #128c7e",
              padding: "5px 20px",
              color: "#128c7e"
            }}>
              Log In
            </Link>
            <button className="createAccount">
              <Link to="/reg">Create an account</Link>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};
const NavbarItem = (props) => {
  return (
    <ul className="navbar-nav ">
      <li className="">
        <NavLink style={{textDecoration: "none"}} to={props.to} className={({ isActive })  =>
          isActive ? "activate" : ""
        }>
          {props.name}
        </NavLink>
      </li>
    </ul>
   
   
  )
}
export default Navbar;
