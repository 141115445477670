import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
import '../styles/reports.scss'
import '../styles/generalTabel.scss'
import img from '../assets/Youtube video player.png'
const SalesCustomerSummaryValues = () => {
  return (
    <div>
      <UserNavbar />
      <div id="RealizedLosses">
        <div className="container repHolder">
          <div className="header">
            <div className="left">
              <h1 style={{ fontSize: 25 }}>Sales by Customer Summary (Values in HC)</h1>
            </div>
            <div className="right">
              <button className="btnOne">Comment</button>
              <button className="btnTwo">Email</button>
              <button className="btnThrree">Print</button>
              <button className="btnThrree">Download Report</button>
              <button className="btnFour">Customer Dashboard</button>
            </div>
          </div>
          <div className="period">
            <div className="d-flex" style={{
              justifyContent: "space-between"
            }}>
              <div className="period">
                <div className="left">
                  <span>Date</span>
                  <div className="dropdown">
                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Custom
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item active" href="#">Action</a></li>
                      <li><a className="dropdown-item" href="#">Another action</a></li>
                      <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </div>
                  <span>From</span>
                  <input type="date" />
                  <span>To</span>
                  <input type="date" />
                </div>
                <div className="right">
                  <span>Show Columns</span>
                  <div className="dropdown">
                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Default
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item active" href="#">Action</a></li>
                      <li><a className="dropdown-item" href="#">Another action</a></li>
                      <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </div>
                  <span>Sort by</span>
                  <div className="dropdown">
                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Default
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item active" href="#">Action</a></li>
                      <li><a className="dropdown-item" href="#">Another action</a></li>
                      <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <img src={img} alt="" style={{
                width: 200,

              }} />
            </div>


          </div>

          <div className="table">
            <table className="table" style={{
              width: '60%',
              margin: "0 auto"
            }}>
              <thead>

                <tr>
                  <th></th>
                  <th scope="col">Nov 1, 22 - Nov 18, 22</th>
                  
                </tr>
              </thead>

              <tr>
                <td>Customer 1</td>
                <td>200</td>
              </tr>
              <tr>
                <td>Customer 1</td>
                <td>200</td>
              </tr>
              <tr>
                <td>Customer 1</td>
                <td>200</td>
              </tr>
              <tr>
                <td>Customer 1</td>
                <td>200</td>
              </tr>
              <tr>
                <td>Customer 1</td>
                <td>200</td>
              </tr>

              <tr>
                <td>
                  TOTAL
                </td>
                <td className="bd">123,840.00</td>
              </tr>
              <tbody>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <FooterBoard />
    </div>
  )
}

export default SalesCustomerSummaryValues 