import UserNavbar from "../usersdashboard/UserNavbar"
import SideBar from "./SideBar"
import '../styles/AccountingSettings.scss'
import RigthBar from "./RigthBar"
const SalesTaxSettings = () => {
  return (
    <div>
      <UserNavbar />
      <div className="container-fluid">
        <h1 className="pt-3 pb-3">Settings</h1>
        <div className="split" id="AccountingSettings">
          <div className="sidebar">
            <SideBar />
          </div>

          <div className="main">
            <div className="boxHoldingSettings">
              <div className="d-flex gap-5">
                <span>Do you charge sales tax</span>
                <div className="d-flex gap-2">
                  <span>Yes</span>
                  <input type="radio" defaultChecked="checked" name="" id="" />
                  <span>No</span>
                  <input type="radio" name="" id="" />
                </div>
              </div>
              <div className="boxex">
                <h4>Set up Sales Tax Item</h4>
                <span>Add a sales tax item for each county, district, parish, etc. where you collect sales tax</span>

                <div className="">
                  <button style={{
                    backgroundColor: "#fff",
                    border: "2px solid #128C7E",
                    padding: "10px",
                    borderRadius: "10px",
                    marginTop: 10,
                    color: "#128C7E"

                  }}>Add sales tax item...</button>
                </div>

                <div className="check mt-2">
                  <span>Your most common sales tax item</span>
                  <div className="">
                    <select style={{
                      marginLeft: 0,
                      padding: 10,
                      width: '200px'
                    }}
                      aria-label="Default select example"
                    >
                      <option selected>Out of State</option>
                      <option value="1">Nigeria</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>

                  </div>

                </div>

              </div>

              <div className="boxex">

                <h4>Assign Sales Tax Codes</h4>
                <span>Sales tax codes determine if the terms you sell are taxable or non-taxable.</span>

                <div className="mt-2">
                  <div className="check">
                    <span>Taxable item code</span>
                    <div className="">
                      <select style={{
                        marginLeft: 0,
                        padding: 10,
                        width: '300px'
                      }}
                        aria-label="Default select example"
                      >
                        <option selected>Tax</option>
                        <option value="1">Nigeria</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>

                    </div>

                  </div>
                  <div className="check mt-2">
                    <span>Non-taxable item code</span>
                    <div className="">
                      <select style={{
                        marginLeft: 0,
                        padding: 10,
                        width: '300px'
                      }}
                        aria-label="Default select example"
                      >
                        <option selected>Non</option>
                        <option value="1">Nigeria</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>

                    </div>

                  </div>
                </div>

                <div className="mt-2">
                  <input type="checkbox" defaultChecked="checked" name="" id="" />
                  <span className="mt-2">Identify taxable amounts as “T” for “Taxable” when printing</span>
                </div>
              </div>

              <div className="boxex">
                <h4>Miscellaneous</h4>
                <div className="">
                  <span>Choose template for invoice packing slip:</span>
                  <div className="">
                    <select style={{
                      marginLeft: 0,
                      padding: 10,
                      width: '300px'
                    }}
                      aria-label="Default select example"
                    >
                      <option selected>Default</option>
                      <option value="1">Nigeria</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>

                  </div>

                </div>

              </div>


              <div className="boxex">
                <h4>When do you owe Sales Tax</h4>
                <div className="d-flex gap-2">
                  <input type="radio" />
                  <span>As of invoice date (Accrual Basis)</span>
                </div>
                <div className="d-flex gap-2">
                  <input type="radio" />
                  <span>Upon receipt of payment (Cash Basis)</span>
                </div>
              </div>

              <div className="boxex">
                <h4>When do you pay Sales Tax</h4>
                <div className="check">
                  <div className="">
                    <input type="radio" name="" id="" />
                    <span>Monthly</span>
                  </div>
                  <div className="">
                    <input type="radio" name="" id="" />
                    <span>Quarterly</span>
                  </div>
                  <div className="">
                    <input type="radio" name="" id="" />
                    <span>Annually</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="extra">
            <RigthBar />
          </div>
        </div>
      </div>

    </div>
  )
}

export default SalesTaxSettings