import React from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const ShowItem = (props) => {
    return (
        <div style={{ width: "50%",padding:10, lineHeight:"25px" }}>
            <NavLink to={props.item.to} style={{ color:"#46484A", textDecoration:"none"}}> {props.item.title} </NavLink>
        </div>
    )
}
const Defa = (props) => {

    const [toogle, SetToogle] = useState(false)
    const handleClick = () => {
        SetToogle(!toogle)
    }
    return (
        <div className="boxex">
            <h5 onClick={() => { handleClick() }} style={{ cursor: "pointer" }}><ArrowDropDownIcon/> { props.title}</h5>
            <div className="innerBoxex" style={{display:!toogle?"flex":"none"}}>
                <div style={{ width: "100%", display: "flex", flexWrap: "wrap",lineHeight:"25px", fontSize:18 }}>
                    {props.items.map(item => <ShowItem item={ item } />)}
                </div>

            </div>
        </div>
    )
}
export default ShowItem;
export { Defa };