import "../styles/dashboardStyle.scss";
import img from "../assets/Logo.png";
import { NavLink } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import ReviewsIcon from '@mui/icons-material/Reviews';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import NotificationsIcon from '@mui/icons-material/Notifications';
const Sidebar = () => {
    return (
        <div id="Sidebar">
            <div className="navBarHolder">
                <div className="fesSection">
                    <img src={img} />
                    <div className=" ones mt-4 d-flex" style={{ alignItems: "center" }}>
                        <DashboardIcon className='icons' />
                        <NavbarItem to='/HomeDashboard' name="Dashboard" />

                    </div>
                </div>
                <hr />
                <div className="secSection">
                    <ul>
                        <NavbarItem to='/Teams' name="Admin Team" icon={<GroupAddIcon className="icons" />} />
                        <NavbarItem to='/Subscribers' name="Subscribers" icon={<PeopleOutlineIcon className="icons" />} />
                        <NavbarItem to='/Companies' name="Companies" icon={<AccountBalanceIcon className="icons" />} />
                        <NavbarItem to='/RegisteredUser' name="Registered users" icon={<HowToRegIcon className="icons" />} />
                       
                    </ul>
                </div>
                <hr />
                <div className="thirdSection">
                    <ul>
                        <NavbarItem to='/PartnerList' name="Partners" icon={<HowToRegIcon className="icons" />} />   
                        <NavbarItem to='/BillingPLan' name="Billing & plans" icon={<LocalAtmIcon className="icons" />} />
                        <NavbarItem to='/Revenue' name="Revenue" icon={<ReviewsIcon className="icons" />} />
                        <NavbarItem to='/Transactions' name="Transactions" icon={<AccountBalanceWalletIcon className="icons" />} />
                       
                    </ul>
                </div>
                <hr />
                <div className="lastSection">
                    <ul>
                        <NavbarItem to='/profile' name="Profile" icon={<AccountCircleIcon className="icons" />} />
                        <NavbarItem to='/Landing' name="Landing page" icon={<CropLandscapeIcon className="icons" />} />
                        <NavbarItem to='/Notifications' name="Notifications" icon={<NotificationsIcon className="icons" />} />
                     
                    </ul>
                </div>
            </div>
        </div>
    );
};
const NavbarItem = (props) => {
    return (

        <NavLink to={props.to} style={{ textDecoration: "none", display: "flex", alignItems: "center", paddingLeft: 20, paddingRight: 20, paddingTop: 8, scrollPaddingBlock: 8 }} className={({ isActive }) =>
            isActive ? "activate" : undefined
        }>
            {props.icon}<li style={{ listStyle: "none" }}>{props.name}</li>
        </NavLink>
    )
}

export default Sidebar;
