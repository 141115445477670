import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
import '../styles/reports.scss'
import '../styles/generalTabel.scss'
import img from '../assets/Youtube video player.png'
const ProfitOrLoss = () => {
    return (
        <div>
            <UserNavbar />
            <div id="RealizedLosses">
                <div className="container repHolder">
                    <div className="header">
                        <div className="left">
                            <h1>Profit or Loss</h1>
                        </div>
                        <div className="right">
                            <button className="btnOne">Comment</button>
                            <button className="btnTwo">Email</button>
                            <button className="btnThrree">Print</button>
                            <button className="btnThrree">Download Report</button>
                            <button className="btnFour">Dashboard</button>
                        </div>
                    </div>
                    <div className="period">
                        <div className="d-flex" style={{
                            justifyContent: "space-between"
                        }}>
                            <div className="period">
                                <div className="left">
                                    <span>Date</span>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Custom
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item active" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>
                                    <span>From</span>
                                    <input type="date" />
                                    <span>To</span>
                                    <input type="date" />
                                </div>
                                <div className="right">
                                    <span>Sort by</span>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Default
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item active" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <img src={img} alt="" style={{
                                width: 200,

                            }} />
                        </div>


                    </div>

                    <div className="table">
                        <table className="table" style={{ width: '60%', margin: '0 auto' }}>
                            <thead>

                                <tr>
                                    <th style={{ width: 350 }}></th>
                                    <th></th>
                                    <th scope="col">2015</th>
                                    <th>2014</th>

                                </tr>
                            </thead>
                            <tr>
                                <td colSpan={4} className="text-center">
                                    Notes
                                </td>

                            </tr>
                            <tr>
                                <td colSpan={4} style={{ fontWeight: 500 }}>
                                    Non-current Assets:
                                </td>
                            </tr>
                            <tr>
                                <td>Property,plant & Equipment</td>
                                <td>1</td>
                                <td>200.00</td>
                                <td>200.00</td>
                            </tr>


                            <tr>
                                <td>Intangible Asset</td>
                                <td>1</td>
                                <td>200.00</td>
                                <td>200.00</td>
                            </tr>
                            <tr>
                                <td colSpan={2}></td>
                                <td className="bd">200.00</td>
                                <td className="bd">200.00</td>
                            </tr>

                            <tr>
                                <td colSpan={2}>Other Asset</td>
                                <td className="bd"></td>
                                <td className="bd">200.00</td>
                            </tr>

                            {/* newLine */}

                            <tr>
                                <td colSpan={4} style={{ fontWeight: 500 }}>
                                    Current Assets:
                                </td>
                            </tr>
                            <tr>
                                <td>Property,plant & Equipment</td>
                                <td>1</td>
                                <td>200.00</td>
                                <td>200.00</td>
                            </tr>


                            <tr>
                                <td>Intangible Asset</td>
                                <td>1</td>
                                <td>200.00</td>
                                <td>200.00</td>
                            </tr>
                            <tr>
                                <td colSpan={2}></td>
                                <td className="bd">200.00</td>
                                <td className="bd">200.00</td>
                            </tr>



                            {/* newLine */}

                            <tr>
                                <td colSpan={4} style={{ fontWeight: 500 }}>
                                    Current Liabilities:
                                </td>
                            </tr>
                            <tr>
                                <td>Property,plant & Equipment</td>
                                <td>1</td>
                                <td>200.00</td>
                                <td>200.00</td>
                            </tr>


                            <tr>
                                <td>Intangible Asset</td>
                                <td>1</td>
                                <td>200.00</td>
                                <td>200.00</td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ fontWeight: 400 }}>Net Current Assets</td>
                                <td className="bd">200.00</td>
                                <td className="bd">200.00</td>
                            </tr>

                            <tr>
                                <td colSpan={2} style={{ fontWeight: 400 }}>Non-current Liabilities:</td>
                                <td className="bd">200</td>
                                <td className="bd">200.00</td>
                            </tr>

                            {/* newLine */}

                            <tr>
                                <td colSpan={4} style={{ fontWeight: 500 }}>
                                    Equity:
                                </td>
                            </tr>
                            <tr>
                                <td>Property,plant & Equipment</td>
                                <td>1</td>
                                <td>200.00</td>
                                <td>200.00</td>
                            </tr>


                            <tr>
                                <td>Intangible Asset</td>
                                <td>1</td>
                                <td>200.00</td>
                                <td>200.00</td>
                            </tr>
                            <tr>
                                <td colSpan={2}></td>
                                <td className="bd">200.00</td>
                                <td className="bd">200.00</td>
                            </tr>


                            <tbody>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <FooterBoard />
        </div>
    )
}
export default ProfitOrLoss