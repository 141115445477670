import '../styles/GeneralNavBar.scss'
import { NavLink } from "react-router-dom";

const GeneralNavBar = (props) => {
    return (
        <div id="GeneralNavBar">

            <NavLink to={props.to} className={({ isActive }) =>
                isActive ? "acting" : undefined
            }><li>{props.name}</li>

            </NavLink>


        </div>
    )
}

export default GeneralNavBar