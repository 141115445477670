import "../styles/addcustomer.scss";
import UserNavbar from "./UserNavbar";
import '../styles/inputs.scss'
import { Link, useNavigate } from "react-router-dom";
import ButtomNav from "../component/ButtomNav";
import { useState } from "react";
import Swal from "sweetalert2";
import GeneralNavBar from "../component/GeneralNavBar";
import CreateUserReUser from "./CreateUserReUser";
const AddNewCustomer = () => {
  const [formData, setFormData] = useState({
    email: '',
  });
  const navigate = useNavigate()
  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (formData.email === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'All fields are required'
      })
    } else if (!formData.email.includes('@')) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Invalid email'
      })
    } else {
      Swal.fire({
        icon: 'success',
        title: 'Password Reset',
        text: 'Password reset link has been sent to your email!'
      })
      navigate('/token')
    }
  };
  return (
    <div>
      <UserNavbar />
      <div className="container"><NavItem /></div>
      <div id="AddNewCustomer">
        <div className="container">
          <div className="formHolderContact">
            <div className="titleHolder">
              <h1>Create New Customer</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                vulputate libero et velit interdum, ac aliquet odio mattis.
              </p>
            </div>

            <form action="" onSubmit={handleSubmit}>
              <CreateUserReUser />
              <div className="address" id="FormInput">
                <div className="tab">
                  <ul>
                    <li className="tabActive"><Link to='/add-customer'>Contact Details</Link></li>
                    <li><Link to='/UserAddress'>Address</Link></li>
                    <li><Link to='/UserAdditionalContact'>Additional Contact</Link></li>
                    <li className=" "><Link to='/UserPayment'>Collections & Payment and Billing</Link></li>
                    <li><Link to='/UserNote'>Notes</Link></li>
                    <li><Link to='/Attachments'>Attachments</Link></li>
                  </ul>
                </div>
                <div className="formHoldering">
                  <div className="row addy">
                    <div className="col-md-6 right">
                      <div>
                        <label htmlFor="">Email</label>
                        <div>
                          <input type="text" placeholder="Separate multiple emails with commas" />
                        </div>
                      </div>

                      <div>
                        <label htmlFor="">Website</label>
                        <div>
                          <input type="text" placeholder="https://" />
                        </div>
                      </div>


                    </div>
                    <div className="col-md-6 left">
                      <div>
                        <label htmlFor="">Telephone</label>
                        <div>
                          <input type="text" placeholder="Enter your telephone number" />
                        </div>
                      </div>

                      <div className="check">
                        <div className="checking">
                          <input style={{ width: 20 }} type="checkbox" />
                          <span>Same as Telephone</span>
                        </div>
                        <div>
                          <label htmlFor="">Mobile</label>
                          <div>
                            <input type="text" placeholder="Enter your mobile number" />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ButtomNav />
    </div>
  )
};
const NavItem = () => {
  return (
    <div className="d-flex navHolder">
      <GeneralNavBar name='Customer Dashboard' to='/csdasboard' />
      <GeneralNavBar name='Add New Customer' to='/add-customer' />
      <GeneralNavBar name='List of Customers' to='/list' />
      <GeneralNavBar name='Customer Sales Order' to='/sales' />
      <GeneralNavBar name='Customer Categories' to='/CustomerCategories' />
      <GeneralNavBar name='Customer Credit Notes' to='/CustomerCredit' />
      <GeneralNavBar name='Customer Receipt' to='/CustomerReceipt' />
      <GeneralNavBar name='List of Sales Reps' to='/ListSales' />
      <GeneralNavBar name='Create Invoice' to='/invoice' />
      <GeneralNavBar name='Process Receipt' to='/ProcessReceipt' />
      <GeneralNavBar name='Customer Write-Offs' to='/CustomerWrite' />
      <GeneralNavBar name='Recurring Invoice' to='/RecurringInvoice' />
    </div>
  )
}
export default AddNewCustomer;
