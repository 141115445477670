
import '../styles/AccountingSettings.scss'
const RigthBar = () => {
  return (
      <div id='RigthBar'>
          <div className="btning">
              <ul>
                  <li className='ok'>OK</li>
                  <li className='help'>Help</li>
                  <li className='help'>Default</li>
                  <li className='cancel'>Cancel</li>
                 
              </ul>
            
          </div>
      </div>
  )
}

export default RigthBar