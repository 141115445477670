import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
import '../styles/reports.scss'
import '../styles/generalTabel.scss'
import img from '../assets/Youtube video player.png'
const InventoryStockItem = () => {
    return (
        <div>
            <UserNavbar />
            <div id="RealizedLosses">
                <div className="container repHolder">
                    <div className="header">
                        <div className="left">
                            <h1>Inventory Stock Status by Item</h1>
                        </div>
                        <div className="right">
                            <button className="btnOne">Comment</button>
                            <button className="btnTwo">Email</button>
                            <button className="btnThrree">Print</button>
                            <button className="btnThrree">Download Report</button>
                            <button className="btnFour">Item Dashboard</button>
                        </div>
                    </div>
                    <div className="period">
                        <div className="d-flex" style={{
                            justifyContent: "space-between"
                        }}>
                            <div className="period">
                                <div className="d-flex" style={{
                                    justifyContent: "space-between"
                                }}>
                                    <div className="period">
                                        <div className="left">
                                            <span>Dates</span>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Today
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item active" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <span>From</span>
                                            <input type="date" />
                                            <span>To</span>
                                            <input type="date" />
                                        </div>
                                    
                                    </div>
                                   
                                </div>


                            </div>
                            <img src={img} alt="" style={{
                                width: 300,

                            }} />
                        </div>


                    </div>

                    <div className="table" style={{
                      
                    }}>
                        <table className="table" style={{
                            width: '1400px'
                        }} >
                            <thead>
                               
                                <tr>
                                    <th></th>
                                    <th >Pref Vendor</th>
                                    <th scope="col">Reorder Pt (Min)</th>
                                    <th scope="col">Max</th>
                                    <th scope="col">On Hand</th>
                                    <th scope="col">On Sales Order</th>
                                    <th scope="col">For Assemblies</th>
                                    <th scope="col">Available</th>
                                    <th scope="col">U/M</th>
                                    <th scope="col">Order</th>
                                    <th>On PO</th>
                                    <th>Reorder Qty</th>
                                    <th>Next Deliv</th>
                                    <th>Sales/Week</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="14">
                                        <h6>Inventory</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>InvenListTop</td>
                                    <td></td>
                                    <td>2</td>
                                    <td>12</td>
                                    <td>5,673</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>1,567</td>
                                    <td></td>
                                    <td></td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td></td>
                                    <td>0.2</td>
                                </tr>
                                <tr>
                                    <td>InvenListTop</td>
                                    <td></td>
                                    <td>2</td>
                                    <td>12</td>
                                    <td>5,673</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>1,567</td>
                                    <td></td>
                                    <td></td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td></td>
                                    <td>0.2</td>
                                </tr>
                                <tr>
                                    <td colspan="14">
                                        <h6>Inventory</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Item Building</td>
                                    <td></td>
                                    <td>2</td>
                                    <td>12</td>
                                    <td>5,673</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>1,567</td>
                                    <td></td>
                                    <td></td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td></td>
                                    <td>0.2</td>
                                </tr>

                                <tr>
                                    <td>Item Bud</td>
                                    <td></td>
                                    <td>2</td>
                                    <td>12</td>
                                    <td>5,673</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>1,567</td>
                                    <td></td>
                                    <td></td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td></td>
                                    <td>0.2</td>
                                </tr>

                              

                            </tbody>
                            <thead />
                        </table>
                    </div>
                </div>
            </div>
            <FooterBoard />
        </div>
    )
}
export default InventoryStockItem