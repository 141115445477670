import "../styles/dashboardStyle.scss";
import img from "../assets/Logo.png";
import { NavLink } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import ReviewsIcon from '@mui/icons-material/Reviews';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HowToRegIcon from '@mui/icons-material/HowToReg';
const Sidebar = () => {
    return (
        <div id="Sidebar">
            <div className="navBarHolder">
                <div className="fesSection">
                    <img src={img} />
                    <div className=" ones mt-4 d-flex" style={{ alignItems: "center" }}>
                        <DashboardIcon className='icons' />
                        <NavbarItem to='/PlanDetails' name="Plan Details" />

                    </div>
                </div>
                <hr />
                <div className="secSection">
                    <ul>
                        <NavbarItem to='/ForecastBudget' name="Forecast & Budget" icon={<GroupAddIcon className="icons" />} />
                        <NavbarItem to='/Subscribers' name="Profit & Loss" icon={<PeopleOutlineIcon className="icons" />} />
                        <NavbarItem to='/Companies' name="Balance Sheet" icon={<AccountBalanceIcon className="icons" />} />
                        <NavbarItem to='/CreateTax' name="Create Tax" icon={<HowToRegIcon className="icons" />} />

                    </ul>
                </div>
                <hr />
                <div className="thirdSection">
                    <ul>
                        <NavbarItem to='/PlanAnalysis' name="Plan Analysis" icon={<HowToRegIcon className="icons" />} />
                        <NavbarItem to='/BillingPLan' name="Schedule" icon={<LocalAtmIcon className="icons" />} />
                        <NavbarItem to='/BillingPLan' name="Dashboard" icon={<LocalAtmIcon className="icons" />} />
                       

                    </ul>
                </div>
                <hr />
                <div className="lastSection">
                    <ul>
                        <NavbarItem to='/BudgetsSettings' name="Settings" icon={<ReviewsIcon className="icons" />} />
                        <NavbarItem to='/profile' name="Invite User" icon={<AccountCircleIcon className="icons" />} />

                    </ul>
                </div>
            </div>
        </div>
    );
};
const NavbarItem = (props) => {
    return (

        <NavLink to={props.to} style={{ textDecoration: "none", display: "flex", alignItems: "center", paddingLeft: 20, paddingRight: 20, paddingTop: 8, scrollPaddingBlock: 8 }} className={({ isActive }) =>
            isActive ? "activate" : undefined
        }>
            {props.icon}<li style={{ listStyle: "none" }}>{props.name}</li>
        </NavLink>
    )
}

export default Sidebar;
