
import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
import '../styles/reports.scss'
import '../styles/generalTabel.scss'
import img from '../assets/Youtube video player.png'
const AverageAllTransactions = () => {
  return (
    <div>
      <UserNavbar />
      <div id="RealizedLosses">
        <div className="container repHolder">
          <div className="header">
            <div className="left">
              <h1>Average Days to Pay (All Transactions)</h1>
            </div>
            <div className="right">
              <button className="btnOne">Comment</button>
              <button className="btnTwo">Email</button>
              <button className="btnThrree">Print</button>
              <button className="btnFour">Customer Dashboard</button>
            </div>
          </div>
          <div className="period">
            <div className="d-flex" style={{
              justifyContent: "space-between"
            }}>
              <div className="period">
                <div className="left">
                  <span>Sort by</span>
                  <div className="dropdown">
                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Class
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item active" href="#">Action</a></li>
                      <li><a className="dropdown-item" href="#">Another action</a></li>
                      <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </div>

                </div>

              </div>
              <img src={img} alt="" style={{
                width: 200,

              }} />
            </div>


          </div>

          <div className="table">
            <table className="table">
              <thead>
                <tr>
                  <th >Type</th>
                  <th scope="col">Due Date</th>
                  <th scope="col">Num</th>
                  <th scope="col">Memo</th>
                  <th scope="col">Account</th>
                  <th scope="col">Class</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Date</th>
                  <th scope="col">Paid Date</th>
                  <th scope="col">Avg Days to Pay</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="10">
                    <h6>Customer 1</h6>
                  </td>
                </tr>
                <tr>
                  <td>Invoice</td>
                  <td>21/02/2021</td>
                  <td>1</td>
                  <td>Opening bal...</td>
                  <td>11100 - Account...</td>
                  <td></td>
                  <td>100,320.00</td>
                  <td>08/01/21</td>
                  <td>08/01/21</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>Invoice</td>
                  <td>21/02/2021</td>
                  <td>1</td>
                  <td>Opening bal...</td>
                  <td>11100 - Account...</td>
                  <td></td>
                  <td>100,320.00</td>
                  <td>08/01/21</td>
                  <td>08/01/21</td>
                  <td>10</td>
                </tr>

                <tr>
                  <td>Invoice</td>
                  <td>21/02/2021</td>
                  <td>1</td>
                  <td>Opening bal...</td>
                  <td>11100 - Account...</td>
                  <td></td>
                  <td>100,320.00</td>
                  <td>08/01/21</td>
                  <td>08/01/21</td>
                  <td>10</td>
                </tr>

                <tr>
                  <td>Invoice</td>
                  <td>21/02/2021</td>
                  <td>1</td>
                  <td>Opening bal...</td>
                  <td>11100 - Account...</td>
                  <td></td>
                  <td>100,320.00</td>
                  <td>08/01/21</td>
                  <td>08/01/21</td>
                  <td>10</td>
                </tr>

                <tr>
                  <td>Invoice</td>
                  <td>21/02/2021</td>
                  <td>1</td>
                  <td>Opening bal...</td>
                  <td>11100 - Account...</td>
                  <td></td>
                  <td>100,320.00</td>
                  <td>08/01/21</td>
                  <td>08/01/21</td>
                  <td>10</td>
                </tr>

                <tr>
                  <td colspan="6">Total Customer 1</td>

                  <td style={{
                    borderTop: '2px solid black'
                  }}>10</td>
                  <td colSpan="2"></td>
                  <td style={{
                    borderTop: '2px solid black'
                  }}>10</td>
                </tr>

                {/* newLine */}
                <tr>
                  <td colspan="10">
                    <h6>General customer</h6>
                  </td>
                </tr>
                <tr>
                  <td>Invoice</td>
                  <td>21/02/2021</td>
                  <td>6</td>
                  <td></td>
                  <td>11100 - Account...</td>
                  <td></td>
                  <td>100,320.00</td>
                  <td>08/01/21</td>
                  <td>08/01/21</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td colspan="6">Total General customer</td>

                  <td style={{
                    borderTop: '2px solid black'
                  }}>10</td>
                  <td colSpan="2"></td>
                  <td style={{
                    borderTop: '2px solid black'
                  }}>10</td>
                </tr>
                <tr>
                  <td colspan="6">TOTAL</td>

                  <td style={{
                    borderTop: '2px solid black'
                  }}>10</td>
                  <td colSpan="2"></td>
                  <td style={{
                    borderTop: '2px solid black'
                  }}>10</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <FooterBoard />
    </div>
  )
}

export default AverageAllTransactions