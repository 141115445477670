import '../styles/dashboardStyle.scss'
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import img from "../assets/Ellipse.png";
import search from '../assets/Frame 156.png'
import notify from '../assets/Frame 157.png'
const NavBoard = () => {
  return (
    <div id='NavBoard'>
      <div className="content">
        <div className="fes">
          <span>Company</span>
          <span class="dropdown">
            <span class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Takemitchy Limited
            </span>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="/">Logout</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </span>
        </div>
        <div className="fes">
          <span>FrameWork</span>
          <span class="dropdown">
            <span class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
               Blue  Investment
            </span>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="/">Logout</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </span>
        </div>
        <div className="las">
          <img src={search} alt="" />
          <img src={notify} alt="" />
          <div className="imgs">
            <img src={img} alt="" />
          </div>
          <span class="dropdown">
            <span class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Super Admin
            </span>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="/">Logout</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </span>
        </div>
       
      </div>
    </div>
  )
}

export default NavBoard