import '../styles/generalTabel.scss'
import '../styles/reports.scss'
import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
const EquityFormat = () => {
    return (
        <div>
            <UserNavbar />
            <div id="RealizedLosses">
                <div className="container repHolder">
                    <div className="titleFormat">
                        <h1>Immaculate Metircs Plus Plc.</h1>
                        <p>Statement of changes in Equity for the year ended December 31,2015</p>
                    </div>

                    <div className="table">
                        <table className="table" style={{ width: '70%', margin: '0 auto' }}>
                            <thead>

                                <tr>
                                    <th style={{ width: "40%" }}></th>
                                    <th scope="col">Ordinary Share Capital</th>
                                    <th scope="col">Retained Earnings</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>000</td>
                                    <td>000</td>
                                    <td>000</td>

                                </tr>
                                <tr>
                                    <td >Opening Balance 1/1/14</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>

                                <tr>
                                    <td>Profit for the year</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>

                                <tr>
                                    <td>Transactions with owners</td>
                                    <td>000</td>
                                    <td>000</td>
                                    <td>000</td>

                                </tr>
                                <tr>
                                    <td >Closing Balance 31/12/14</td>
                                    <td className="bd">200.00</td>
                                    <td className="bd">200.00</td>
                                    <td className="bd">200.00</td>
                                </tr>

                                <tr>
                                    <td>Opening Balance1/1/15</td>
                                    <td className="bd">200.00</td>
                                    <td className="bd">200.00</td>
                                    <td className="bd">200.00</td>
                                </tr>





                                {/* newLine */}

                                <tr>
                                    <td>Profit for the year</td>
                                    <td>000</td>
                                    <td>000</td>
                                    <td>000</td>

                                </tr>
                                <tr>
                                    <td >Opening Balance 1/1/14</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>

                                <tr>
                                    <td>Profit for the year</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>

                                <tr>
                                    <td>Transactions with owners</td>
                                    <td>000</td>
                                    <td>000</td>
                                    <td>000</td>

                                </tr>
                                <tr>
                                    <td >Closing Balance 31/12/14</td>
                                    <td className="bd">200.00</td>
                                    <td className="bd">200.00</td>
                                    <td className="bd">200.00</td>
                                </tr>

                                <tr>
                                    <td>Opening Balance1/1/15</td>
                                    <td className="bd">200.00</td>
                                    <td className="bd">200.00</td>
                                    <td className="bd">200.00</td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <FooterBoard />
        </div>
    )
}
export default EquityFormat