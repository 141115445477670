import '../styles/generalTabel.scss'
import '../styles/reports.scss'
import img from '../assets/Youtube video player.png'
import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
import { useState } from "react";
import ShowItem, { Defa } from '../component/ShowItem';
const Reports = () => {
    let bitems = [{
        to: "/RealizedLosses",
        title: "Realized Gains & Losses"
    },
    {
        to: "/Unrealized",
        title: "Unrealized Gains & Losses"
        },
        {
            to: "/ExpensesSummary",
            title: "Expenses by Vendor Summary"
        },
        {
            to: "/IncomeCustomerDetail",
            title: "Income by Customer Detail"
        },
        {
            to: "/IncomeCustomerSummary",
            title: "Income by Customer Summary"
        },
    {
        to: "/ProfitLossComparison",
        title: "Profit & Loss YTD Comparison"
        },
        {
            to: "/ProfitLossPrevYearComparison",
            title: "Profit & Loss Prev Year Comparison"
        },
        {
            to: "/ProfitandLossDetail",
            title: "Profit & Loss Detail"
        }
    ];
    const financialItem = [{
        to: "/FinancialPositionbyClass",
        title: "Financial Position by Class"
    },
    {
        to: "/PageTwo",
        title: "Financial Position Prev Year Comparison"
    },
    {
        to: "/PageThree",
        title: "Financial Position by Quarter"
    },
    {
        to: "/PageFour",
        title: "Financial Position by Month"
    },
    {
        to: "/PageFive",
        title: "Financial Position by Total (Summary)"
    },
    {
        to: "/PageSix",
        title: "Financial Position by Total only (fiscal year-to-date)"
    },
    {
        to: "/PageSeven",
        title: "Statement of Cash Flows"
    },
   
    ];
    const CUSTOMER = [{
        to: "/AverageAllTransactions",
        title: "Average Days to Pay by Class"
    },
    {
        to: "/AveragePageThree",
        title: "Customer Contact List"
    },
    {
        to: "/CustomerPhoneList",
        title: "Customer Phone List"
    },
    {
        to: "/TransactionCustomer",
        title: "Transaction List by Customer"
    },
    {
        to: "/AgingDetail",
        title: "A/R Aging Detail"
    },
    {
        to: "/RAgingSummary",
        title: "A/R Aging Summary (Values in Home Currency)"
    },
    {
        to: "/CustomerBalanceSummary",
        title: "Customer Balance Summary (Values in Home Currency)"
        },
    
        {
            to: "/CustomerBalanceDetail",
            title: "Customer Balance Detail"
        },

        {
            to: "/CollectionsReport",
            title: "Collections Report"
        },
        {
            to: "/OpenInvoices",
            title: "Open Invoices"
        },

    ];
    const BANK = [{
        to: "/CheckDetail",
        title: "Check Detail"
    },
        {
            to: "/DepositDetail",
            title: "Deposit Detail"
        }, 
    ]
    const INVENTORY = [
        {
            to: "/InventoryValuationDetail",
            title: "Inventory Valuation Detail"
        },
        {
            to: "/PhysicalInventoryWorksheet",
            title: "Physical Inventory Worksheet"
        },
        {
            to: "/InventoryStockItem",
            title: "Inventory Stock Status by Item"
        },
        {
            to: "/InventoryValuationSummary",
            title: " Inventory Valuation Summary"
        },
        {
            to: "/ItemListing",
            title: "Item Listing"
        },
        {
            to: "/TermsListing",
            title: "Terms Listing"
        },
       

    ]
    const OTHERS = [
        {
            to: "/AccountListing",
            title: "Account Listing"
        },
        {
            to: "/AdjustingJournalEntries",
            title: "Adjusting Journal Entries"
        },
        {
            to: "/AdjustedTrialBalance",
            title: "Adjusted Trial Balance"
        },
        {
            to: "/AuditTrail",
            title: "Audit Trail"
        },
        {
            to: "/FixedAssetListing",
            title: "Fixed Asset Listing"
        },
        {
            to: "/GeneralLedger",
            title: "General Ledger"
        },
        {
            to: "/IncomeTaxPreparation",
            title: "Income Tax Preparation"
        },
        {
            to: "/Journal",
            title: "Journal"
        },
        {
            to: "/ItemPriceList",
            title: "Item Price List"
        },
        {
            to: "/TransactionDetailsAccount",
            title: "Transaction Details by Account"
        },
        {
            to: "/TrialBalances",
            title: "Trial Balance"
        },
        {
            to: "/VoidedDeletedTransactionsSummary",
            title: "Voided/Deleted Transactions Summary"
        },
        {
            to: "/ToDoNotes",
            title: "To Do Notes"
        },

    ]
    const SALES = [{
        to: "/EstimatebyJob",
        title: "Estimate by Job"
    },
    {
        to: "/ActualsSummary",
        title: "Job Estimates vs. Actuals Summary"
    },
    {
        to: "/JobProfitabilitySummary",
        title: "Job Profitability Summary"
    },
    {
        to: "/OpenSalesOrdersbyCustomer",
        title: "Open Sales Orders by Item"
    },
    {
        to: "/OpenSalesOrderstem",
        title: "Open Sales by Customer"
    },
    {
        to: "/SalesCustomerDetail",
        title: "Open Sales by Customer"
    },
    {
        to: "/SalesCustomerSummaryValues",
        title: "Sales by Customer Summary (Values in Home Currency)"
        },
        {
            to: "/SalesbyRepSummary",
            title: "Sales by Rep Summary"
        },
        {
            to: "/SalesRepSummaryTwo",
            title: "Sales by Item Summary"
        },
        {
            to: "/SalesRepSummaryThree",
            title: "Sales by Item Detail"
        },

    ];
    const VENDORS = [
        {
            to: "/OpenPurchaseOrders",
            title: "Open Purchase Orders"
        },
        {
            to: "/OpenPurchaseOrdersbyJob",
            title: "Open Purchase Orders by Job"
        },
        {
            to: "/PurchasesbyItemDetail",
            title: "Purchases by Item Detail"
        },
        {
            to: "/PurchasesbyItemSummary",
            title: "Purchases by Item Summary"
        },
        {
            to: "/PurchasesbyVendorDetail",
            title: "Purchases by Vendor Detail"
        },
        {
            to: "/SalesTaxLiability",
            title: "Sales Tax Liability"
        },
        {
            to: "/TransactionListbyVendor",
            title: "Transaction List by Vendor"
        },
        {
            to: "/UnpaidBillsDetail",
            title: "Unpaid Bills Detail"
        },
        {
            to: "/ARAgingSummaryValuesinHC",
            title: "A/R Aging Summary (Values in Home Currency)"
        },
        {
            to: "/VendorARAgingDetails",
            title: "A/R Aging Detail"
        },
        {
            to: "/VendorSummaryValuesHC",
            title: "Vendor Summary (Values in Home Currency)"
        },
        {
            to: "/VendorBalanceDetail",
            title: "Vendor Balance Detail"
        },
        {
            to: "/VendorContactList",
            title: "Vendor Contact List"
        },

    ]
    return (
        <div>
            <UserNavbar />
            <div id="RealizedLosses">
                <div className="container repHolder">
                    <div className="header">
                        <div className="left">
                            <h1>Reports</h1>
                        </div>
                        <div className="right">
                            <button className="btnOne">Comment</button>
                            <button className="btnTwo">Email</button>
                            <button className="btnThrree">Print</button>
                            <button className="btnThrree">Download Report</button>
                            <button className="btnFour">Customer Dashboard</button>
                        </div>
                    </div>
                    <div className="period">
                        <div className="d-flex" style={{
                            justifyContent: "space-between"
                        }}>
                            <div className="period">

                                <div className="right">
                                    <span>Sort by</span>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Default
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item active" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <img src={img} alt="" style={{
                                width: 200,

                            }} />
                        </div>


                    </div>

                    <div className="contentRep p-5 d-flex gap-2" style={{ flexDirection: "column" }}>
                        <h3>All Reports</h3>
                        <Defa title={"PROFIT OR LOSS REPORT"} items={bitems} />
                        <Defa title={"FINANCIAL POSITION"} items={financialItem} />
                        <Defa title={"CUSTOMER RECEIVABLES"} items={CUSTOMER} />
                        <Defa title={"BANK"} items={BANK} />
                        <Defa title={"INVENTORY"} items={INVENTORY} />
                        <Defa title={"OTHERS"} items={OTHERS} />
                        <Defa title={"SALES"} items={SALES} />
                        <Defa title={"VENDORS"} items={VENDORS} />
                    </div>
                </div>
            </div>
            <FooterBoard />
        </div>
    )
}


const aaz = (props) => {
    return (
        <div style={{ background: "red !important" }}> {props.children} </div>
    )
}

const ToogleMenu = () => {
    const [toogle, SetToogle] = useState(false)
    const handleClick = () => {
        SetToogle(!toogle)
    }
    return (
        <div>

        </div>
    )
}

const ItemHolder = () => {
    return (
        <div>
            <div className="left">

            </div>
        </div>
    )
}
export default Reports