import React from 'react'
import { NavLink } from "react-router-dom";

const SideBar = () => {
    return (

        <div id='SideBar'>

            <ul>
                <NavbarItem to='/AccountingSettings' name="Accounting"/>
                <NavbarItem to='/BillsSettings' name="Bill" />
                <NavbarItem to='/GeneralSettings' name="General" />
                <NavbarItem to='/ItemsInventorySettings' name="Items & Inventory" />
                <NavbarItem to='/PaymentsSettings' name="Payments" />
                <NavbarItem to='/PayrollEmployees' name="Payroll & Employees" />
                <NavbarItem to='/ReportsGraphs' name="Reports & Graphs" />
                <NavbarItem to='/SalesCustomers' name="Sales & Customers" />
                <NavbarItem to='/SalesTaxSettings' name="Sales Tax" />
                <NavbarItem to='/ManageCurrencies' name="Send Forms" />
            </ul>
        </div>
    )
}
const NavbarItem = (props) => {
    return (
        <NavLink to={props.to} className={({ isActive }) =>
            isActive ? "act" : undefined
        }><li>{ props.name}</li></NavLink>
    )
}

export default SideBar