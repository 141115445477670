
import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
import '../styles/reports.scss'
import '../styles/generalTabel.scss'
import img from '../assets/Youtube video player.png'
const VendorSummaryValuesHC = () => {
    return (
        <div>
            <UserNavbar />
            <div id="RealizedLosses">
                <div className="container repHolder">
                    <div className="header">
                        <div className="left">
                            <h1 style={{
                                fontSize: 20
                            }}>Vendor Summary (Values in HC)</h1>
                        </div>
                        <div className="right">
                            <button className="btnOne">Comment</button>
                            <button className="btnTwo">Email</button>
                            <button className="btnThrree">Print</button>
                            <button className="btnThrree">Download Report</button>
                            <button className="btnFour">Vendor Dashboard</button>
                        </div>
                    </div>
                    <div className="period">
                        <div className="d-flex" style={{
                            justifyContent: "space-between"
                        }}>
                            <div className="period">
                                <div className="left">
                                    <span>Dates</span>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Today
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item active" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>

                                    <input type="date" />
                                    <span>Interval (days)</span>
                                    <input type="text" style={{ width: 40 }} placeholder="30" />
                                </div>
                                <div className="right">
                                    <span>Sort by</span>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Default
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item active" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <img src={img} alt="" style={{
                                width: 200,

                            }} />
                        </div>


                    </div>

                    <div className="table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th ></th>
                                    <th scope="col">Sep 30, 22</th>
                                    <th scope="col">Oct 31, 22</th>
                                    <th scope="col">Nov 18, 22</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td>Vendor 1</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>
                                <tr>
                                    <td>Vendor 1</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>
                                <tr>
                                    <td>Vendor 1</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>
                                <tr>
                                    <td>Vendor 1</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>
                                <tr>
                                     <td>Vendor 1</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>

                                <tr> <td colspan="">Total</td>

                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                </tr>

                            </tbody>
                            <thead />
                        </table>
                    </div>
                </div>
            </div>
            <FooterBoard />
        </div>
    )
}


export default VendorSummaryValuesHC