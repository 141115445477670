import '../styles/table.scss'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
const Intable = ({data}) => {
  
    return (
      <div>
        <div id="Intable">
          <table className="table mt-5">
            <thead className="heading">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Item</th>
                <th scope="col">Selection</th>
                <th scope="col">Description</th>
                <th scope="col">QTY</th>
                <th scope="col">Class</th>
                <th scope="col">Amount</th>
                <th scope="col">Disc %</th>
                <th scope="col">VAT %</th>
                <th scope="col">VAT </th>
                <th scope="col">Total</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((listDatas) => (
                <tr key={listDatas.id}>
                  <th scope="row">{listDatas.id}</th>
                  <td>
                    {" "}
                    <select class="role" aria-label="Default select example">
                      <option selected>{listDatas.Type}</option>
                      <option value="1">Nigeria</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </td>
                      <td>
                          <select class="role" aria-label="Default select example">
                              <option selected>{listDatas.Selection}</option>
                              <option value="1">Nigeria</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                          </select>
                      </td>
                  <td>{listDatas.Disc}</td>
                  <td>{listDatas.QTY}</td>
                      <td>
                          <select class="role" aria-label="Default select example">
                              <option selected>{listDatas.Class}</option>
                              <option value="1">Nigeria</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                          </select>
                      </td>
                  <td>{listDatas.Amount}</td>
                  <td>{listDatas.Disc}%</td>
                  <td>{listDatas.VAT}%</td>
                  <td>{listDatas.VAT}</td>
                  <td>{listDatas.Total}</td>
                  <td>
                    <DeleteForeverIcon />
                  </td>
                  <td>
                    <ModeEditOutlineIcon />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
}

export default Intable