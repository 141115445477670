import '../styles/generalTabel.scss'
import '../styles/reports.scss'
import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
const CashFlowFormat = () => {
    return (
        <div>
            <UserNavbar />
            <div id="RealizedLosses">
                <div className="container repHolder">
                    <div className="titleFormat">
                        <h1>Immaculate Metircs Plus Plc.</h1>
                        <p>Statement of cash flows for the year ended December 31,2015</p>
                    </div>

                    <div className="table">
                        <table className="table" style={{width:"70%", margin:"0 auto"}}>
                            <thead>
                                <tr>
                                    <th colSpan={6}></th>
                                    <th scope="col" style={{ borderBottom: '1px solid black' }} colSpan={3}>2015</th>
                                    <th scope="col" style={{ borderBottom: '1px solid black' }} colSpan={3}>2014</th>
                                </tr>
                                <tr>
                                    <th colSpan={6}></th>
                                    <th scope="col">Debit</th>
                                    <th scope="col">Credit</th>
                                    <th scope="col">Debit</th>
                                    <th scope="col">Credit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                </tr>
                                <tr> <td colSpan={10} style={{ fontWeight: 500 }}>Operating Activities:</td></tr>
                                <tr>
                                    <td colSpan={6}>Profit before tax</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>

                                </tr>
                                <tr>
                                    <td colSpan={6} >Adjust for non-cash item</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>

                                <tr>
                                    <td colSpan={6}>Adjust for non-cash item</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>

                                <tr> <td colSpan={10} style={{ fontWeight: 500 }}>Investing Activities</td></tr>
                                <tr>
                                    <td colSpan={6}>Profit before tax</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>

                                </tr>
                                <tr>
                                    <td colSpan={6} >Adjust for non-cash item</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>

                                <tr>
                                    <td colSpan={6}>Adjust for non-cash item</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>

                                <tr> <td colSpan={10} style={{ fontWeight: 500 }}>Financing Activities</td></tr>
                                <tr>
                                    <td colSpan={6}>Profit before tax</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>

                                </tr>
                                <tr>
                                    <td colSpan={6} >Adjust for non-cash item</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>

                                <tr>
                                    <td colSpan={6}>Adjust for non-cash item</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                    <td>200.00</td>
                                </tr>


                                {/* newLine */}


                                <tr>
                                    <td colspan="6" style={{ fontWeight: 500 }}>Cash & Cash equivalent at start 1/1/2015</td>


                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>200</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>200</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>200</td>

                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>200</td>
                                </tr>

                                <tr>
                                    <td colspan="6" style={{ fontWeight: 500 }}>Cash & Cash equivalent at start 1/1/2015</td>


                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>200</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>200</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>200</td>

                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>200</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <FooterBoard />
        </div>
    )
}

export default CashFlowFormat