import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
import '../styles/reports.scss'
import '../styles/generalTabel.scss'
import img from '../assets/Youtube video player.png'
const GeneralLedger = () => {
    return (
        <div>
            <UserNavbar />
            <div id="RealizedLosses">
                <div className="container repHolder">
                    <div className="header">
                        <div className="left">
                            <h1>General Ledger</h1>
                        </div>
                        <div className="right">
                            <button className="btnOne">Comment</button>
                            <button className="btnTwo">Email</button>
                            <button className="btnThrree">Print</button>
                            <button className="btnThrree">Download Report</button>
                            <button className="btnFour">Budget Dashboard</button>
                        </div>
                    </div>
                    <div className="period">
                        <div className="d-flex" style={{
                            justifyContent: "space-between"
                        }}>
                            <div className="period">
                                <div className="left">
                                    <span>Dates</span>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Custom
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item active" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>
                                    <span>From</span>
                                    <input type="date" name="" id="" />
                                    <span>To</span>
                                    <input type="date" name="" id="" />
                                </div>

                            </div>
                            <img src={img} alt="" style={{
                                width: 200,

                            }} />
                        </div>


                    </div>

                    <div className="table">
                        <table className="table" style={{
                            width: 1200
                        }}>
                            <thead>
                                <tr>
                                    <th >Type</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Num</th>
                                    <th scope="col">Adj</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Memo</th>
                                    <th scope="col">Split</th>
                                    <th scope="col">Debit</th>
                                    <th scope="col">Credit</th>
                                    <th scope="col">Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={11}>
                                        <h4>88000 - Inventory Offset Account (Inventory offset liability account for items received and items billed.)</h4>
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td>Bill</td>
                                    <td>30/03/2022   12:31:15</td>
                                    <td>1</td>
                                    <td></td>
                                    <td>Laptop supplier</td>
                                    <td>Sales Tax</td>
                                    <td>11001 - Accounts Receivable - CAD...</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>
                                <tr>
                                    <td>Bill</td>
                                    <td>30/03/2022   12:31:15</td>
                                    <td>1</td>
                                    <td></td>
                                    <td>Laptop supplier</td>
                                    <td>Sales Tax</td>
                                    <td>11001 - Accounts Receivable - CAD...</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>


                                <tr>
                                    <td>Bill</td>
                                    <td>30/03/2022   12:31:15</td>
                                    <td>1</td>
                                    <td></td>
                                    <td>Laptop supplier</td>
                                    <td>Sales Tax</td>
                                    <td>11001 - Accounts Receivable - CAD...</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>


                                <tr>
                                    <td>Bill</td>
                                    <td>30/03/2022   12:31:15</td>
                                    <td>1</td>
                                    <td></td>
                                    <td>Laptop supplier</td>
                                    <td>Sales Tax</td>
                                    <td>11001 - Accounts Receivable - CAD...</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>


                                <tr>
                                    <td colSpan={7}>
                                        <h4>Total - 88000 - Inventory Offset Account (Inventory offset liability account for items received and items billed.)- </h4>
                                    </td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                </tr>

                                <tr>
                                    <td colSpan={11}>
                                        <h4>88000 - Inventory Offset Account (Inventory offset liability account for items received and items billed.)</h4>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Bill</td>
                                    <td>30/03/2022   12:31:15</td>
                                    <td>1</td>
                                    <td></td>
                                    <td>Laptop supplier</td>
                                    <td>Sales Tax</td>
                                    <td>11001 - Accounts Receivable - CAD...</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>
                                <tr>
                                    <td>Bill</td>
                                    <td>30/03/2022   12:31:15</td>
                                    <td>1</td>
                                    <td></td>
                                    <td>Laptop supplier</td>
                                    <td>Sales Tax</td>
                                    <td>11001 - Accounts Receivable - CAD...</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>


                                <tr>
                                    <td>Bill</td>
                                    <td>30/03/2022   12:31:15</td>
                                    <td>1</td>
                                    <td></td>
                                    <td>Laptop supplier</td>
                                    <td>Sales Tax</td>
                                    <td>11001 - Accounts Receivable - CAD...</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>


                                <tr>
                                    <td>Bill</td>
                                    <td>30/03/2022   12:31:15</td>
                                    <td>1</td>
                                    <td></td>
                                    <td>Laptop supplier</td>
                                    <td>Sales Tax</td>
                                    <td>11001 - Accounts Receivable - CAD...</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>


                                <tr>
                                    <td colSpan={7}>
                                        <h4>Total - 88000 - Inventory Offset Account (Inventory offset liability account for items received and items billed.)- </h4>
                                    </td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                </tr>

                                <tr>
                                    <td colSpan={11}>
                                        <h4>88000 - Inventory Offset Account (Inventory offset liability account for items received and items billed.)</h4>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Bill</td>
                                    <td>30/03/2022   12:31:15</td>
                                    <td>1</td>
                                    <td></td>
                                    <td>Laptop supplier</td>
                                    <td>Sales Tax</td>
                                    <td>11001 - Accounts Receivable - CAD...</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>
                                <tr>
                                    <td>Bill</td>
                                    <td>30/03/2022   12:31:15</td>
                                    <td>1</td>
                                    <td></td>
                                    <td>Laptop supplier</td>
                                    <td>Sales Tax</td>
                                    <td>11001 - Accounts Receivable - CAD...</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>


                                <tr>
                                    <td>Bill</td>
                                    <td>30/03/2022   12:31:15</td>
                                    <td>1</td>
                                    <td></td>
                                    <td>Laptop supplier</td>
                                    <td>Sales Tax</td>
                                    <td>11001 - Accounts Receivable - CAD...</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>


                                <tr>
                                    <td>Bill</td>
                                    <td>30/03/2022   12:31:15</td>
                                    <td>1</td>
                                    <td></td>
                                    <td>Laptop supplier</td>
                                    <td>Sales Tax</td>
                                    <td>11001 - Accounts Receivable - CAD...</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>


                                <tr>
                                    <td colSpan={7}>
                                        <h4>Total - 88000 - Inventory Offset Account (Inventory offset liability account for items received and items billed.)- </h4>
                                    </td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                </tr>

                                <tr>
                                    <td colSpan={11}>
                                        <h4>88000 - Inventory Offset Account (Inventory offset liability account for items received and items billed.)</h4>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Bill</td>
                                    <td>30/03/2022   12:31:15</td>
                                    <td>1</td>
                                    <td></td>
                                    <td>Laptop supplier</td>
                                    <td>Sales Tax</td>
                                    <td>11001 - Accounts Receivable - CAD...</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>
                                <tr>
                                    <td>Bill</td>
                                    <td>30/03/2022   12:31:15</td>
                                    <td>1</td>
                                    <td></td>
                                    <td>Laptop supplier</td>
                                    <td>Sales Tax</td>
                                    <td>11001 - Accounts Receivable - CAD...</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>


                                <tr>
                                    <td>Bill</td>
                                    <td>30/03/2022   12:31:15</td>
                                    <td>1</td>
                                    <td></td>
                                    <td>Laptop supplier</td>
                                    <td>Sales Tax</td>
                                    <td>11001 - Accounts Receivable - CAD...</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>


                                <tr>
                                    <td>Bill</td>
                                    <td>30/03/2022   12:31:15</td>
                                    <td>1</td>
                                    <td></td>
                                    <td>Laptop supplier</td>
                                    <td>Sales Tax</td>
                                    <td>11001 - Accounts Receivable - CAD...</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                </tr>


                                <tr>
                                    <td colSpan={7}>
                                        <h4>Total - 88000 - Inventory Offset Account (Inventory offset liability account for items received and items billed.)- </h4>
                                    </td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                </tr>
                               


                            </tbody>
                            <thead />
                        </table>
                    </div>
                </div>
            </div>
            <FooterBoard />
        </div>
    )
}

export default GeneralLedger