import React, {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import img from "../assets/Logo.png";
import '../styles/password-forgot.scss'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Swal from "sweetalert2";

const ForgotPassword = () => {
    const [formData, setFormData] = useState({
        email: '',
    });
    const navigate = useNavigate()
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (formData.email === '') {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'All fields are required'
            })
        } else if (!formData.email.includes('@')) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid email'
            })
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Password Reset',
                text: 'Password reset link has been sent to your email!'
            })
            navigate('/token')
        }
    };
    return (
        <div id='ForgotPassword' onSubmit={handleSubmit}>
            <div className="loginHolder">
                <div className="img">
                    <img src={img} alt="" />
                </div>
                <div className="titleHolderLogin">
                    <h1>Forgot Password?</h1>
                    <p>Enter your email and we'll send you instructions to reset your password</p>
                </div>
                <div className="formHolder">
                    <form>
                        <div className="mail">
                            <label>Email Address</label>
                            <input type="text" value={formData.email} name="email" onChange={handleChange} placeholder='Email Address' />
                        </div>
                    
                        <div className="button">
                            <button type="submit">Send reset link</button>

                        </div>
                        <p className='dont'><ArrowBackIosIcon className='back' /><Link to='/login'>Back To Login</Link></p>
                    </form>
                </div>
            </div>
            <div className="tabNav">
                <p>Accounting Saas</p>
                <p>Accounting Saas</p>
                <p>Privacy Policy</p>
            </div>
        </div>
    )
}

export default ForgotPassword