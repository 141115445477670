import "../styles/addcustomer.scss";
import Footer from "../component/Footer";
import UserNavbar from "./UserNavbar";
import '../styles/inputs.scss'
import imgIcon from "../assets/fluent_folder-48-filled.png";
import { Link } from "react-router-dom";
import CreateUserReUser from "./CreateUserReUser";
import ButtomNav from "../component/ButtomNav";
import React from "react";
const Attachments = () => {
    return (
        <div>
            <UserNavbar />
            <div id="AddNewCustomer">
                <div className="container">
                    <div className="formHolderContact">
                        <div className="titleHolder">
                            <h1>Create New Customer</h1>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                                vulputate libero et velit interdum, ac aliquet odio mattis.
                            </p>
                        </div>

                       <CreateUserReUser/>
                        <div className="address">
                            <div className="tab">
                                <ul>
                                    <li><Link to='/add-customer'>Contact Details</Link></li>
                                    <li><Link to='/UserAddress'>Address</Link></li>
                                    <li><Link to='/UserAdditionalContact'>Additional Contact</Link></li>
                                    <li className=""><Link to='/UserPayment'>Collections & Payment and Billing</Link></li>
                                    <li><Link to='/UserNote'>Notes</Link></li>
                                    <li className="tabActive"><Link to='/Attachments'>Attachments</Link></li>
                                </ul>
                            </div>
                            <div className="formHoldering">
                                <div className="row addy">
                                  
                                  
                                    <div className="attachments right">
                                        <label htmlFor="">Upload Documents</label>
                                        <div className="uploadHolder">
                                            <img src={imgIcon} alt="" />
                                            <input type="file" />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ButtomNav/>
        </div>
    )
};


export default Attachments