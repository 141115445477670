import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
import '../styles/reports.scss'
import '../styles/generalTabel.scss'
import img from '../assets/Youtube video player.png'
const OpenSalesOrderstem = () => {
  return (
    <div>
      <UserNavbar />
      <div id="RealizedLosses">
        <div className="container repHolder">
          <div className="header">
            <div className="left">
              <h1>Estimate by Job</h1>
            </div>
            <div className="right">
              <button className="btnOne">Comment</button>
              <button className="btnTwo">Email</button>
              <button className="btnThrree">Print</button>
              <button className="btnThrree">Download Report</button>
              <button className="btnFour">Customer Dashboard</button>
            </div>
          </div>
          <div className="period">
            <div className="d-flex" style={{
              justifyContent: "space-between"
            }}>
              <div className="period">
                <div className="left">
                  <span>Date</span>
                  <div className="dropdown">
                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Custom
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item active" href="#">Action</a></li>
                      <li><a className="dropdown-item" href="#">Another action</a></li>
                      <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </div>
                  <span>From</span>
                  <input type="date" />
                  <span>To</span>
                  <input type="date" />
                </div>
                <div className="right">
                  <span>Sort by</span>
                  <div className="dropdown">
                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Default
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item active" href="#">Action</a></li>
                      <li><a className="dropdown-item" href="#">Another action</a></li>
                      <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <img src={img} alt="" style={{
                width: 200,

              }} />
            </div>


          </div>

          <div className="table">
            <table className="table">
              <thead>

                <tr>
                  <th>Type</th>
                  <th scope="col">Date</th>
                  <th scope="col">Num</th>
                  <th scope="col">Memo</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Balance</th>
                </tr>
              </thead>
              <tr>
                <td colSpan={6}>
                  <h4>Customer 1</h4>
                </td>
              </tr>
              <tr>
                <td>Estimate</td>
                <td>31/10/2022</td>
                <td>1</td>
                <td></td>
                <td></td>
                <td>3,307,711.06</td>
              </tr>

              <tr>
                <td colSpan={5}>
                  Total Customer
                </td>
                <td className="bd">3,307,711.06</td>
              </tr>

              <tr>
                <td colSpan={6}>
                  <h4>Purchase Order Customer</h4>
                </td>
              </tr>
              <tr>
                <td>Estimate</td>
                <td>31/10/2022</td>
                <td>1</td>
                <td></td>
                <td></td>
                <td>3,307,711.06</td>
              </tr>

              <tr>
                <td colSpan={5}>
                  Total Customer
                </td>
                <td className="bd">3,307,711.06</td>
              </tr>

              <tr>
                <td colSpan={5}>
                  TOTAL
                </td>
                <td className="bd" style={{
                  borderBottom: '2px solid black'
                }}>3,307,711.06</td>
              </tr>

              <tbody>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <FooterBoard />
    </div>
  )
}

export default OpenSalesOrderstem