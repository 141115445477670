import "../styles/addcustomer.scss";
import UserNavbar from "./UserNavbar";
import '../styles/inputs.scss'
import { Link } from "react-router-dom";
import Footer from "../component/Footer";
import ButtomNav from "../component/ButtomNav";
import CreateUserReUser from "./CreateUserReUser";
const AdditionalContactInfo = () => {
  return (
    <div>
      <UserNavbar />
      <div id="AddNewCustomer">
        <div className="container">
          <div className="formHolderContact">
            <div className="titleHolder">
              <h1>Create New Customer</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                vulputate libero et velit interdum, ac aliquet odio mattis.
              </p>
            </div>

          <CreateUserReUser/>
            <div className="address" id="FormInput">
              <div className="tab">
                <ul>
                  <li ><Link to='/add-customer'>Contact Details</Link></li>
                  <li><Link to='/UserAddress'>Address</Link></li>
                  <li className="tabActive"><Link to='/UserAdditionalContact'>Additional Contact</Link></li>
                  <li className=""><Link to='/UserPayment'>Collections & Payment and Billing</Link></li>
                  <li><Link to='/UserNote'>Notes</Link></li>
                  <li><Link to='/Attachments'>Attachments</Link></li>
                </ul>
              </div>
              <div className="formHoldering">
                <div className="row addy">
                  <div className="additionalContact">
                    <div className="row">
                      <div className="col-md-6 right">
                        <div>
                          <label htmlFor="">Contact Name</label>
                          <div>
                            <input type="text" placeholder="Enter contact name" />
                          </div>
                        </div>

                        <div>
                          <label htmlFor="">Enter email address</label>
                          <div>
                            <input type="text" placeholder="Email" />
                          </div>
                        </div>


                      </div>
                      <div className="col-md-6 left">
                        <div>
                          <label htmlFor="">Designation</label>
                          <div>
                            <input type="text" placeholder="Role" />
                          </div>
                        </div>

                        <div className="mt-2">
                          <label htmlFor="">Mobile</label>
                          <div>
                            <input type="text" placeholder="Enter your mobile number" />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ButtomNav/>
    </div>
  )
};


export default AdditionalContactInfo