import UserNavbar from "../usersdashboard/UserNavbar";
import FooterBoard from "../dashboard/FooterBoard";
import '../styles/reports.scss'
import '../styles/generalTabel.scss'
import img from '../assets/Youtube video player.png'
const InventoryValuationSummary = () => {
    return (
        <div>
            <UserNavbar />
            <div id="RealizedLosses">
                <div className="container repHolder">
                    <div className="header">
                        <div className="left">
                            <h1>Inventory Valuation Summary</h1>
                        </div>
                        <div className="right">
                            <button className="btnOne">Comment</button>
                            <button className="btnTwo">Email</button>
                            <button className="btnThrree">Print</button>
                            <button className="btnFour">Customer Dashboard</button>
                        </div>
                    </div>
                    <div className="period">
                        <div className="d-flex" style={{
                            justifyContent: "space-between"
                        }}>
                            <div className="period">
                                <div className="left">
                                    <span>Dates</span>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Today
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item active" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>
                                    <span>As of</span>
                                    <input type="date" />

                                </div>

                            </div>
                            <img src={img} alt="" style={{
                                width: 200,

                            }} />
                        </div>


                    </div>

                    <div className="table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th ></th>
                                    <th scope="col">On Hand</th>
                                    <th scope="col">U/M</th>
                                    <th scope="col">Avg Cost</th>
                                    <th scope="col">Asset Value</th>
                                    <th scope="col">% of Tot Asset</th>
                                    <th scope="col">Sales Price</th>
                                    <th scope="col">Retail Value</th>
                                    <th scope="col">% of Tot Retail</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="9">
                                        <h6>Inventory</h6>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Inventory Adjust</td>
                                    <td>1,223</td>
                                    <td></td>
                                    <td>5,000.40</td>
                                    <td>8,898,900.23</td>
                                    <td>43%</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>43%</td>
                                </tr>
                                <tr>
                                    <td>Inventory Adjust</td>
                                    <td>1,223</td>
                                    <td></td>
                                    <td>5,000.40</td>
                                    <td>8,898,900.23</td>
                                    <td>43%</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>43%</td>
                                </tr>


                                <tr>
                                    <td colspan="">Total InvenListTop</td>

                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>1,223</td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}></td>
                                    <td></td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>8,898,900.23</td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>43%</td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>43%</td>
                                </tr>

                                {/* newLine */}


                               

                                <tr>
                                    <td colspan="9">
                                        <h6>Inventory</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Inventory Adjust</td>
                                    <td>1,223</td>
                                    <td></td>
                                    <td>5,000.40</td>
                                    <td>8,898,900.23</td>
                                    <td>43%</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>43%</td>
                                </tr>
                                <tr>
                                    <td>Inventory Adjust</td>
                                    <td>1,223</td>
                                    <td></td>
                                    <td>5,000.40</td>
                                    <td>8,898,900.23</td>
                                    <td>43%</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>43%</td>
                                </tr>


                                <tr>
                                    <td colspan="">Total InvenListTop</td>

                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>1,223</td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}></td>
                                    <td></td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>8,898,900.23</td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>43%</td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>43%</td>
                                </tr>

                                {/* newLine */}


                                <tr>
                                    <td colspan="9">
                                        <h6>Inventory</h6>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Inventory Adjust</td>
                                    <td>1,223</td>
                                    <td></td>
                                    <td>5,000.40</td>
                                    <td>8,898,900.23</td>
                                    <td>43%</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>43%</td>
                                </tr>
                                <tr>
                                    <td>Inventory Adjust</td>
                                    <td>1,223</td>
                                    <td></td>
                                    <td>5,000.40</td>
                                    <td>8,898,900.23</td>
                                    <td>43%</td>
                                    <td>100,320.00</td>
                                    <td>100,320.00</td>
                                    <td>43%</td>
                                </tr>


                                <tr>
                                    <td colspan="">Total InvenListTop</td>

                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>1,223</td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}></td>
                                    <td></td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>8,898,900.23</td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>43%</td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td colSpan="1" style={{
                                        borderTop: '2px solid black'
                                    }}>43%</td>
                                </tr>

                                {/* newLine */}


                                <tr>
                                    <td colspan="">Total</td>
                                    <td colSpan="">1,223</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td></td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>43%</td>
                                    <td></td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>100,320.00</td>
                                    <td style={{
                                        borderTop: '2px solid black'
                                    }}>43%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <FooterBoard />
        </div>
    )
}

export default InventoryValuationSummary